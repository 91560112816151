// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sidebar {

  background-color: rgb(255, 255, 255, 1.0) !important;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
  
}

.sidebar2{
  
  background-color: rgb(255, 255, 255, 1.0) !important;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}

.sidebar-item {
  margin-bottom: 10px;
  cursor: pointer;
}


.sidebar-item-active{
  background-color: rgb(200, 0, 4,1.0);
  color: white;
padding: 5px;
border-radius: 15px;

}



.sidebar-item-active img{
    filter: invert(100%);

}

.sidebar_img {
  max-width: 20px;
  height: 100%; 
  margin-right: 10px; 

}
.sidebarLogo {
  max-width: 160px;
  margin-right: 10px; 
  margin-bottom: 25px;

}


.sidebar_text{
    font-weight: bold;
}



@media (max-width: 576px) {
  .sidebar_text {
    font-size: 12px;
  }

}

/* Medium screens */
@media (min-width: 577px) and (max-width: 992px) {

  .sidebar_text {
    font-size: 15px;
  }
}

/* Large screens */
@media (min-width: 993px) {

  .sidebar_text {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/CSS/sidebar.css"],"names":[],"mappings":";AACA;;EAEE,oDAAoD;EACpD,aAAa;EACb,wCAAwC;;AAE1C;;AAEA;;EAEE,oDAAoD;EACpD,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;;AAGA;EACE,oCAAoC;EACpC,YAAY;AACd,YAAY;AACZ,mBAAmB;;AAEnB;;;;AAIA;IACI,oBAAoB;;AAExB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,kBAAkB;;AAEpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;;AAErB;;;AAGA;IACI,iBAAiB;AACrB;;;;AAIA;EACE;IACE,eAAe;EACjB;;AAEF;;AAEA,mBAAmB;AACnB;;EAEE;IACE,eAAe;EACjB;AACF;;AAEA,kBAAkB;AAClB;;EAEE;IACE,eAAe;EACjB;AACF","sourcesContent":["\n.sidebar {\n\n  background-color: rgb(255, 255, 255, 1.0) !important;\n  padding: 20px;\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); \n  \n}\n\n.sidebar2{\n  \n  background-color: rgb(255, 255, 255, 1.0) !important;\n  padding: 20px;\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); \n}\n\n.sidebar-item {\n  margin-bottom: 10px;\n  cursor: pointer;\n}\n\n\n.sidebar-item-active{\n  background-color: rgb(200, 0, 4,1.0);\n  color: white;\npadding: 5px;\nborder-radius: 15px;\n\n}\n\n\n\n.sidebar-item-active img{\n    filter: invert(100%);\n\n}\n\n.sidebar_img {\n  max-width: 20px;\n  height: 100%; \n  margin-right: 10px; \n\n}\n.sidebarLogo {\n  max-width: 160px;\n  margin-right: 10px; \n  margin-bottom: 25px;\n\n}\n\n\n.sidebar_text{\n    font-weight: bold;\n}\n\n\n\n@media (max-width: 576px) {\n  .sidebar_text {\n    font-size: 12px;\n  }\n\n}\n\n/* Medium screens */\n@media (min-width: 577px) and (max-width: 992px) {\n\n  .sidebar_text {\n    font-size: 15px;\n  }\n}\n\n/* Large screens */\n@media (min-width: 993px) {\n\n  .sidebar_text {\n    font-size: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
