// Footer.js
import React from 'react';
import {isMobile} from 'react-device-detect';

import './CSS/Footer.css'; // Import your CSS stylesheet for the footer


function Footer() {
  return (
        <footer className="footer">
         {!isMobile ? (
    <div className="desktop_footer_content">
      <div>Copyright © 2023 SentriHub - All Rights Reserved.</div>

       <div>Powered By SentriHub</div>

    </div>
    ) : (
      <div className="mobile_footer_content">
     <div>Copyright © 2023 SentriHub - All Rights Reserved.</div>
        <div> Powered By SentriHub</div>
      </div>
    )}

       
      </footer>
  );
}

export default Footer;
