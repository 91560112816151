import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'react-bootstrap';

import './Table.css';

const TrafficAnalysisTable = ({ json }) => {
  const og_title = json['analysis']
  let title = ""
  if (og_title == "trafficAnalysis"){
    title = "Traffic Analysis"
  }
   if (og_title == "threatProtection"){
    title = "Threat Protection"
  }
   if (og_title == "lossPrevention"){
    title = "Loss Prevention"
  }
  const video_name = json['key']
  const jsonData = json["excelFile"]
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100;

function replaceUnderscores(inputString) {
    return inputString.replace(/_/g, ' ');
}

  useEffect(() => {
  // Convert your JSON data to the format [{ Row: key, Count: value }, ...]
  const processedData = Object.keys(jsonData).map(key => ({
    Row: replaceUnderscores(key),
    Count: jsonData[key]
  }));

  setData(processedData);
}, [jsonData]);


  const pageCount = Math.ceil(data.length / rowsPerPage);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const getRowRangeText = () => {
    const startRow = (currentPage - 1) * rowsPerPage + 1;
    const endRow = Math.min(currentPage * rowsPerPage, data.length);
    return `Rows ${startRow} to ${endRow} of ${data.length}`;
  };

  return (
    <>
<div className="tableHeader">
<br/>
<div className="tableHeader2">
{/*<h3> {title +" "+ video_name}</h3>*/}
</div>
      <div className="data_table">

        <div className="inner_table">
          <Table bordered hover>
            <thead>
              <tr>
                {/* Table header content */}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
  <tr key={index} className="tablerows">
    <td className="tablecat">{row.Row}</td>
    <td className="tablecount">{row.Count}</td>
  </tr>
))}
            </tbody>
          </Table>
        </div>
        <div className="pagitems">
          <span className="pagination-text">{getRowRangeText()}</span>

          <button
            className={`arrow-button ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="arrow left"></i>
          </button>
          <button
            className={`arrow-button ${currentPage === pageCount ? 'disabled' : ''}`}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pageCount}
          >
            <i className="arrow right"></i>
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default TrafficAnalysisTable;
