import React, { useState, useEffect } from 'react';
import './CSS/main_app.css'; // This imports the CSS stylesheet for this component
import { useNavigate,useLocation } from 'react-router-dom';

import Footer from './Footer'; // I
import Sidebar from './Sidebar'; // 
import Header from './Header'; // 
import { Container, Row, Col, Form, Button, ListGroup,ButtonGroup  } from 'react-bootstrap';
import axios from 'axios';

import './CSS/Dashboard.css'; // 

import { apiUrl } from './api';

import {isMobile} from 'react-device-detect';

import { updateDashboardMargin } from './helperFunctions'; // Import the function
import './CSS/Users.css'



import { useKeycloak } from "@react-keycloak/web";


const adjustElementMinHeight = () => {
  console.log("C");
  const cards2 = document.querySelectorAll('.container');
  const cards = document.querySelectorAll('.main-content2');
  const header = document.querySelector('.header'); // replace with your header class or ID
  const footer = document.querySelector('.footer'); // replace with your footer class or ID

  if (cards.length > 0 && header && footer) {
    let headerHeight = header.offsetHeight;
    let footerHeight = footer.offsetHeight;  
    cards.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight}px)`;
    });
      cards2.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight+headerHeight}px)`;
      card.style.marginBottom = `calc(${footerHeight}px)`;
    });
  }
  };



function Dashboard() {
      useEffect(() => {
    // Run the function on component mount
    adjustElementMinHeight();

    // Add event listener for window resize
    window.addEventListener('resize', adjustElementMinHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustElementMinHeight);
    };
  }, []); // Empty dependency array means it will only run once on mount
   const {state} = useLocation();
  const {sidebar_state} = state
 
  const navigate = useNavigate(); 
  const { keycloak } = useKeycloak();


  const [sidebarVisible, setSidebarVisible] = useState(sidebar_state);
 function handleConnectClick(){
  if (keycloak.authenticated){
    if (!isAIChecked){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"safetyWithAI":true,"lossPrevention":true,"trafficAnalysis":true,"threatProtection":true}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));
    setAIChecked(true)
    navigate('/connect',{ state: { sidebar_state: sidebarVisible } })
  }
  else{
     navigate('/connect',{ state: { sidebar_state: sidebarVisible } })

  }
  }
  }

  async function handleAIChecked(){
    if (keycloak.authenticated){
  
      setLossPreventionChecked(!isAIChecked)
      setTrafficAnalysisChecked(!isAIChecked)
      setThreatProtectionChecked(!isAIChecked)

  let body = {"email":keycloak.idTokenParsed.email,"data":{"safetyWithAI":!isAIChecked,"lossPrevention":!isAIChecked,"trafficAnalysis":!isAIChecked,"threatProtection":!isAIChecked}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));

    await setAIChecked(!isAIChecked)
    if (!isAIChecked){
      navigate('/connect',{ state: { sidebar_state: sidebarVisible } })
    }
    }
  }
    async function handleLossChecked(){
     if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"lossPrevention":!lossPreventionChecked}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));
    await setLossPreventionChecked(!lossPreventionChecked)
    }
  }
    async function handleTrafficCheck(){
  if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"trafficAnalysis":!trafficAnalysisChecked}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));
    await setTrafficAnalysisChecked(!trafficAnalysisChecked)
    
}
  }
    async function handleThreatCheck(){
    if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"threatProtection":!threatProtectionChecked}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));

    await setThreatProtectionChecked(!threatProtectionChecked)
    
}
  }
      async function handleBigBossCheck(){
  if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"bigBoss":!bigBossChecked}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));
    await setBigBossChecked(!bigBossChecked)
    
  }
  }
  async function handleWorkForceCheck(){
  if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"workForce":!workForce}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));
    await setWorkForce(!workForce)
    
  }
  }
  async function handleStaffSchedulingCheck(){
  if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email,"data":{"staffScheduling":!staffScheduling}}
  axios.post(`${apiUrl}/updateDict`,body)
        .then((response) => {
         console.log("Success")
          })
        .catch((error) => console.log(error));
    await setStaffScheduling(!staffScheduling)
  }
  }


  useEffect(() => {
  window.addEventListener('margin_updater', updateDashboardMargin);
  updateDashboardMargin(); // Initial update on mount
  return () => {
    window.removeEventListener('margin_updater', updateDashboardMargin);
  };
}, [sidebarVisible]); 

  const [isAIChecked, setAIChecked] = useState(false);
  const [lossPreventionChecked, setLossPreventionChecked] = useState(false);
  const [trafficAnalysisChecked, setTrafficAnalysisChecked] = useState(false);
  const [threatProtectionChecked, setThreatProtectionChecked] = useState(false);
  const [bigBossChecked, setBigBossChecked] = useState(false);
  const [workForce,setWorkForce] = useState(false)
  const [staffScheduling,setStaffScheduling] = useState(false)

 if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email}
  axios.post(`${apiUrl}/getDict`,body)
        .then((response) => {
          setAIChecked(response.data.safetyWithAI)
          setLossPreventionChecked(response.data.lossPrevention)
          setBigBossChecked(response.data.bigBoss)
          setTrafficAnalysisChecked(response.data.trafficAnalysis)
          setThreatProtectionChecked(response.data.threatProtection)
          setWorkForce(response.data.workForce)
          setStaffScheduling(response.data.staffScheduling)

          })
        .catch((error) => console.log(error));
    }


  return (
    <div className="dashboard-container">

    <div className="dashboard">
        {sidebarVisible && <Sidebar active="settings" sidebar_state={sidebarVisible}/>}
      <div className="main-content">
  <Header toggleMenu={() => setSidebarVisible(!sidebarVisible) && updateDashboardMargin} />
    <div className="main-content2">
<div className="users_cont">
<Container> 
      <br/>
    <Row className="mb-3">
        <Col>
          <h1>Modules</h1>
        </Col>
      </Row>
      <Row>
<div className = "checkbox_container">
          <ListGroup as="ul">
      <ListGroup.Item as="li">
      <div className="connectContainer">
        <Form.Check type="checkbox" label="Safety with AI" checked={isAIChecked} onChange={handleAIChecked} />
          <Button variant="outline-danger" type="submit" className="connectButton" onClick={handleConnectClick}>
            Connect
          </Button>
        </div>
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Traffic Analysis" checked={trafficAnalysisChecked} onChange={handleTrafficCheck} disabled={!isAIChecked}/>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Loss Prevention" checked={lossPreventionChecked} onChange={handleLossChecked} disabled={!isAIChecked}/>
          </ListGroup.Item>
           <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Threat Protection" checked={threatProtectionChecked} onChange={handleThreatCheck} disabled={!isAIChecked}/>
          </ListGroup.Item>
           <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Sensors for Freezers" disabled />
          </ListGroup.Item>
        </ListGroup>
      </ListGroup.Item>
    </ListGroup>
              <ListGroup as="ul">
      <ListGroup.Item as="li">
        <Form.Check type="checkbox" label="Customers" disabled/>
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Feedback Light" disabled />
          </ListGroup.Item>
        
        </ListGroup>
      </ListGroup.Item>
    </ListGroup>

             <ListGroup as="ul">
      <ListGroup.Item as="li">
        <Form.Check type="checkbox" label="Maintenance Contacts" disabled/>
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Vendor IN/OUT" disabled/>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Repairman" disabled/>
          </ListGroup.Item>
        </ListGroup>
      </ListGroup.Item>
    </ListGroup>
       <ListGroup as="ul">
  <ListGroup.Item as="li">
    <Form.Check type="checkbox" label="BigBoss" checked={bigBossChecked} onChange={handleBigBossCheck}/>
    <ul>
      <li style={{ listStyleType: 'square' }}>Warranty folder with expiration alerts</li>
      <li style={{ listStyleType: 'square' }}>
        Insurance folder with expiration alerts
        <ul>
          <li style={{ listStyleType: 'square' }}>AI Analysis of your policy</li>
          <li style={{ listStyleType: 'square' }}>Shop for savings in policy</li>
        </ul>
      </li>
      <li style={{ listStyleType: 'square' }}>Secure messaging with accountant</li>
    </ul>
  </ListGroup.Item>
</ListGroup>

         <ListGroup as="ul">
      <ListGroup.Item as="li">
        <Form.Check type="checkbox" label="Workforce" checked={workForce} onChange={handleWorkForceCheck}/>
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Intake w.ID scan" disabled/>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Background Checks" disabled/>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Open Job Vacancies" disabled/>
          </ListGroup.Item>
           <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Secure Messaging" disabled/>
          </ListGroup.Item>
           <ListGroup.Item as="li">
            <Form.Check type="checkbox" label="Staff Scheduling" checked={staffScheduling} onChange={handleStaffSchedulingCheck}/>
          </ListGroup.Item>
        </ListGroup>
      </ListGroup.Item>
    </ListGroup>
    </div>
      </Row>
      <Row className="mt-4">
   
      </Row>

    </Container>   
    </div>
  
    </div>
               
    </div>

</div>
<Footer/>
    </div>

  );
}





export default Dashboard;
