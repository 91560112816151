import React, { useState, useEffect, useRef } from 'react';
import './CSS/main_app.css'; // This imports the CSS stylesheet for this component
import Footer from './Footer'; // I
import Sidebar from './Sidebar'; // 
import Header from './Header'; // 
import { Card, ListGroup, Container, Row, Col,Table, Accordion, Button,Form} from 'react-bootstrap';
import { apiUrl } from './api';
import dummy_img from './123.png'
import './CSS/Dashboard.css'; // 
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';

import {isMobile} from 'react-device-detect';

import { updateDashboardMargin, CameraForm } from './helperFunctions'; // Import the function
import './CSS/Users.css'


import { useKeycloak } from "@react-keycloak/web";


import image1 from './assets/hikvision_logo.png'; // Replace with the actual path to your images
import image2 from './assets/ezviz_logo.avif';



const adjustElementMinHeight = () => {

  const cards2 = document.querySelectorAll('.container');
  const cards = document.querySelectorAll('.main-content2');
  const header = document.querySelector('.header'); // replace with your header class or ID
  const footer = document.querySelector('.footer'); // replace with your footer class or ID

  if (cards.length > 0 && header && footer) {
    let headerHeight = header.offsetHeight;
    let footerHeight = footer.offsetHeight;  
    cards.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight}px)`;
    });
      cards2.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight+headerHeight}px)`;
      card.style.marginBottom = `calc(${footerHeight}px)`;
    });
  }
  };



function Connect() {
        useEffect(() => {
    // Run the function on component mount
    adjustElementMinHeight();

    // Add event listener for window resize
    window.addEventListener('resize', adjustElementMinHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustElementMinHeight);
    };
  }, []); // Empty dependency array means it will only run once on mount
    const { keycloak } = useKeycloak();



    const {state} = useLocation();
  const {sidebar_state} = state

  const [vid_arr, setVidArr] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [admin,setAdmin] = useState(false)
  const [adminData,setAdminData] = useState(null)
  const [adminData2,setAdminData2] = useState(null)
  const [adminTemp,setAdminTemp] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [begin, setBegin] = useState(null)

  const [xx, setXx] = useState(null);
    const [yy,setYy] = useState(null)
      const [customNumberX, setCustomNumberX] = useState('');
  const [customNumberY, setCustomNumberY] = useState('');

  const handleSelectX = (number) => {
    setXx(number);
  };

  const handleSelectY = (number) => {
    setYy(number);
  };

  const handleCustomNumberXChange = (event) => {
    setCustomNumberX(event.target.value);
  };

  const handleCustomNumberYChange = (event) => {
    setCustomNumberY(event.target.value);
  };

  const handleCustomNumberXSubmit = () => {
    const parsedNumber = parseInt(customNumberX, 10);

    if (!isNaN(parsedNumber)) {
      setXx(parsedNumber);
      setCustomNumberX('');
    } else {
      alert('Please enter a valid number for X.');
    }
  };

  const handleCustomNumberYSubmit = () => {
    const parsedNumber = parseInt(customNumberY, 10);

    if (!isNaN(parsedNumber)) {
      setYy(parsedNumber);
      setCustomNumberY('');
    } else {
      alert('Please enter a valid number for Y.');
    }
  };

  const handleUserClick = async (user) => {
    setSelectedUser(user);
    setThumbnailData(null)
    setSelectedVideo(null)
    setBegin(null)

  };
   const handleVideoClick = async (video) => {
    setSelectedVideo(video);
    setBegin(true)
  };
const handleChangeState = () => {
  // Create a shallow copy of adminData using the spread operator
  let temp = { ...adminData };

  // Update adminData with the values from adminData2
  setAdminData({ ...adminData2 });

  // Update adminData2 with the values from the temporary variable
  setAdminData2(temp);

  setSelectedUser(null)
  setSelectedVideo(null)
  setThumbnailData(null)

};


 
  const [sidebarVisible, setSidebarVisible] = useState(sidebar_state);



 const fetchData = async () => {
    try {
      let body = { "email": keycloak.idTokenParsed.email };
      const response = await axios.post(`${apiUrl}/getList`, body);
      const data = response.data;

      await setVidArr(data);
     
  }
catch(error){
  console.log(error)

}};
useEffect(() => {
  fetchData();
}, []);

const fetchData2 = async () => {
    try {
      let body = { "email": keycloak.idTokenParsed.email };
      const response = await axios.post(`${apiUrl}/adminGetVideos`, body);
      const data = response.data
      setAdminData(data)
      setAdmin(true)
  }
catch(error){
  console.log("you are not admin")
  console.log(error)
}};
useEffect(() => {
  fetchData2();
}, []);

const fetchData3 = async () => {
    try {
      let body = { "email": keycloak.idTokenParsed.email };
      const response = await axios.post(`${apiUrl}/adminGetVideos2`, body);
      const data = response.data
      setAdminData2(data)
  }
catch(error){
  console.log("you are not admin")
  console.log(error)
}};
useEffect(() => {
  fetchData3();
}, []);





const fetchThumbnail = async()=>{
  console.log(selectedVideo)
  console.log(selectedUser)
  try{
  const response = await fetch(`${apiUrl}/getThumbnail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
videoKey: selectedVideo,
"email":selectedUser
  }),
  });
  if (!response.ok) {
    alert("Unabled to upload video successfully. Please try again later.")
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.arrayBuffer();
  
  const blob = new Blob([data], { type: 'image/png' });
  const imageUrl = URL.createObjectURL(blob);
  setThumbnailData(imageUrl);
  }
  catch{}



}

const [startTime,setStartTime] = useState(null);
const [startCoordinate, setStartCoordinate] = useState(null);
  const [endCoordinate, setEndCoordinate] = useState(null);
const [lossStartCoordinate, setLossStartCoordinate] = useState(null);
  const [lossEndCoordinate, setLossEndCoordinate] = useState(null);

  const [firstCoordinate, setFirstCoordinate] = useState(null);
const [fourthCoordinate, setFourthCoordinate] = useState(null);
const [secondCoordinate, setSecondCoordinate] = useState(null);
  const [thirdCoordinate, setThirdCoordinate] = useState(null);
    const imageRef = useRef(null);
    const renderPolygon = () => {
  if (firstCoordinate && secondCoordinate && thirdCoordinate && fourthCoordinate) {
      console.log("Y")
         const a= drawLine(firstCoordinate,secondCoordinate)
         const b =  drawLine(secondCoordinate,thirdCoordinate)
         const c = drawLine(thirdCoordinate,fourthCoordinate)
         const d =  drawLine(fourthCoordinate,firstCoordinate)
        return (
          <div>
          {a}
          {b}
          {c}
          {d}
          </div>)


  }

  return null;
};

  const handleDateTimeChange = (event) => {
    const dateTimeValue = event.target.value;
  
    setSelectedDateTime(dateTimeValue);
  };
const handleImageClickLoss = (e) =>{

      const xCoordinate = e.nativeEvent.offsetX;
    const yCoordinate = e.nativeEvent.offsetY;


if (!firstCoordinate && !secondCoordinate && !thirdCoordinate && !fourthCoordinate){
  setFirstCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && !secondCoordinate && !thirdCoordinate && !fourthCoordinate){
  setSecondCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && secondCoordinate && !thirdCoordinate && !fourthCoordinate){
  setThirdCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && secondCoordinate && thirdCoordinate && !fourthCoordinate){
  setFourthCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && secondCoordinate && thirdCoordinate && fourthCoordinate){
  setFirstCoordinate({ x: xCoordinate, y: yCoordinate })
  setSecondCoordinate(null)
  setThirdCoordinate(null)
  setFourthCoordinate(null)
}
}
  const handleImageClick = (e) => {

     const xCoordinate = e.nativeEvent.offsetX;
    const yCoordinate = e.nativeEvent.offsetY;

    if (startCoordinate && endCoordinate){
     
      setStartCoordinate({ x: xCoordinate, y: yCoordinate });
      setEndCoordinate(null)
    }
    else if (!startCoordinate) {
      // Set the starting point
      setStartCoordinate({ x: xCoordinate, y: yCoordinate });
    } else {
      // Set the ending point and draw the line
  
      setEndCoordinate({ x: xCoordinate, y: yCoordinate });
      // You can now use these coordinates to draw lines or send them to someone
    }
  };
    const handleLossImageClick = (e) => {

     const xCoordinate = e.nativeEvent.offsetX;
    const yCoordinate = e.nativeEvent.offsetY;

    if (lossStartCoordinate && lossEndCoordinate){
     
      setLossStartCoordinate({ x: xCoordinate, y: yCoordinate });
      setLossEndCoordinate(null)
    }
    else if (!lossStartCoordinate) {
      // Set the starting point
      setLossStartCoordinate({ x: xCoordinate, y: yCoordinate });
    } else {
      // Set the ending point and draw the line
  
      setLossEndCoordinate({ x: xCoordinate, y: yCoordinate });
      // You can now use these coordinates to draw lines or send them to someone
    }
  };
   const renderDot = (pass_var) => {
  const getDotStyle = (x, y, rotate = 0) => ({
    position: 'absolute',
    top: y - 2.5,
    left: x - 2.5,
    width: 10,
    height: 10,
    backgroundColor:'rgb(200, 0, 4,1.0)'
  });
  var [startX, startY] = [null,null]
  var [endX, endY] = [null,null]
  var [secondX,secondY] =[null,null]
  var [thirdX,thirdY] = [null,null]
  if (pass_var=="normal"){
    if (startCoordinate){
       [startX, startY] = [startCoordinate.x, startCoordinate.y];
    }
    if (endCoordinate){
       [endX, endY] = [endCoordinate.x, endCoordinate.y];
    }
    return (
      <>
        {startCoordinate && <div className="dot" style={getDotStyle(startX, startY)} />}
        {endCoordinate && <div className="dot" style={getDotStyle(endX, endY)} />}
      </>
    );
    }
 

   else if (pass_var=="loss"){

    if (lossStartCoordinate){
       [startX, startY] = [lossStartCoordinate.x, lossStartCoordinate.y];
    }
    if (lossEndCoordinate){
       [endX, endY] = [lossEndCoordinate.x, lossEndCoordinate.y];
    }
    return (
      <>
        {lossStartCoordinate && <div className="dot" style={getDotStyle(startX, startY)} />}
        {lossEndCoordinate && <div className="dot" style={getDotStyle(endX, endY)} />}
      </>
    );
    }


  else if (pass_var=="polygon"){
       if (firstCoordinate){
       [startX, startY] = [firstCoordinate.x, firstCoordinate.y];
    }
    if (fourthCoordinate){
       [endX, endY] = [fourthCoordinate.x, fourthCoordinate.y];
    }
     if (thirdCoordinate){
       [thirdX, thirdY] = [thirdCoordinate.x, thirdCoordinate.y];
    }
       if (secondCoordinate){
       [secondX, secondY] = [secondCoordinate.x, secondCoordinate.y];
    }

    return (
      <>

        {firstCoordinate && <div className="dot" style={getDotStyle(startX, startY)} />}
        {fourthCoordinate && <div className="dot" style={getDotStyle(endX, endY)} />}
        {secondCoordinate && <div className="dot" style={getDotStyle(secondX, secondY)} />}
        {thirdCoordinate && <div className="dot" style={getDotStyle(thirdX, thirdY)} />}
      </>
    );
  }

  return null;
};


const drawLine = (startCoord,endCoord) => {
  const [startX, startY] = [startCoord.x, startCoord.y];
  const [endX, endY] = [endCoord.x, endCoord.y];
  const length = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
  const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI);

  const lineStyle = {
    position: 'absolute',
    top: startY,
    left: startX,
    width: length,
    transform: `rotate(${angle}deg)`,
    transformOrigin: 'top left',
    borderBottom: '5px solid rgb(200, 0, 4,1.0)',
    position: 'absolute',
  };

  return (
    <div className="line" style={{position:'absolute',
    top:startY,
    left:startX,
    width:length,
    transform: `rotate(${angle}deg)`,
    transformOrigin: 'top left', // Set transform origin for correct rotation
    borderBottom: '5px solid rgb(200, 0, 4,1.0)',
    position: 'absolute',}} />
  );
};

   const renderLine = (line_var) => {
    if (line_var!="loss"){
    if (startCoordinate && endCoordinate){
     return drawLine(startCoordinate,endCoordinate)
    }
    }
    if (lossStartCoordinate && lossEndCoordinate){
     return drawLine(lossStartCoordinate,lossEndCoordinate)
    }

    
    return null;
  };

  const videoRef = useRef(null);

  const [selectedDateTime, setSelectedDateTime] = useState(null);

    const [thumbnailData, setThumbnailData] = useState(null);
  const [finalCoordinates,setFinalCoordinates] = useState(null)
  const [lossFinalCoordinates,setLossFinalCoordinates] = useState(null)
  const [polygonCoordinates,setPolygonCoordinates] = useState(null)
  const scaled_coord_calc = (old_obj) =>{
    const x = old_obj['x']
    const y = old_obj['y']
     let offsetwidth = imageRef.current.offsetWidth
    let offsetheight = imageRef.current.offsetHeight
    let naturalheight = imageRef.current.naturalHeight
    let naturalwidth = imageRef.current.naturalWidth

    const scaling_x = naturalwidth/offsetwidth
    const scaling_y = naturalheight/offsetheight
 
    const new_x= Math.floor(x*scaling_x)
    const new_y =Math.floor(y*scaling_y)
    const obj = {'x':new_x,'y':new_y}

    return obj
  }

  const handleFinalCoordinates = async ()=>{
    if (startCoordinate && endCoordinate){
        await setFinalCoordinates(true)
    alert("The coordinates have been confirmed.")
  }
  else{

    alert("Missing Coordinates")
  }
  
  }

    const handleLossFinalCoordinates = async ()=>{

      if (lossStartCoordinate && lossEndCoordinate){
          await setLossFinalCoordinates(true)
          alert("The coordinates have been confirmed.")
      }
        else{
    alert("Missing Coordinates")
  }
  }

    const handlePolygonCoordinates = async ()=>{
      if (firstCoordinate && secondCoordinate && thirdCoordinate && fourthCoordinate){
          setPolygonCoordinates(true)
          alert("The coordinates have been confirmed.")
      }
            else{
    alert("Missing Coordinates")
  }

  }




  const commenceTrafficAnalysis = async () => {
    let extension_name = selectedVideo
  
try {
    const formattedDateTime = new Date(selectedDateTime)
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/\//g, '-').replace(/,/g, '');  // Replace commas with spaces


  const new_start = scaled_coord_calc(startCoordinate)
  const new_end = scaled_coord_calc(endCoordinate)

  const response = await fetch(`${apiUrl}/trafficAnalysis`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      email: selectedUser,
      token:keycloak.token,
      line_start : [new_start['x'],new_start['y']],
      line_end : [new_end['x'],new_end['y']],
      video_start_time : formattedDateTime,
      xx : xx,
      yy : yy
  }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    alert("Processing for Traffic Analysis has started successfully!")
  }  

} catch (error) {
    alert("Unabled to start Traffic Analysis successfully. Please try again later.")
  console.error(error);
}
  };



   const commenceLossPrevention = async () => {
    let extension_name = selectedVideo
  
try {
      const formattedDateTime = new Date(selectedDateTime)
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/\//g, '-').replace(/,/g, '');  // Replace commas with spaces


    const new_start = scaled_coord_calc(lossStartCoordinate)
  const new_end = scaled_coord_calc(lossEndCoordinate)

    const newfirst = scaled_coord_calc(firstCoordinate)
  const newsecond = scaled_coord_calc(secondCoordinate)
    const newthird = scaled_coord_calc(thirdCoordinate)
  const newfourth = scaled_coord_calc(fourthCoordinate)

  const response = await fetch(`${apiUrl}/lossPrevention`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      email: selectedUser,
      token:keycloak.token,
      line_start : [new_start['x'],new_start['y']],
      line_end : [new_end['x'],new_end['y']],
      firstCoordinate:[newfirst['x'],newfirst['y']],
      secondCoordinate: [newsecond['x'],newsecond['y']],
      thirdCoordinate:[newthird['x'],newthird['y']],
      fourthCoordinate:[newfourth['x'],newfourth['y']],
      video_start_time : formattedDateTime,
  }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    alert("Processing for Loss Prevention has started successfully!")
  }  

} catch (error) {
    alert("Unabled to start Traffic Analysis successfully. Please try again later.")
  console.error(error);
}
  };


const commenceThreatProtection = async () => {
    let extension_name = selectedVideo
  
try {
      const formattedDateTime = new Date(selectedDateTime)
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/\//g, '-').replace(/,/g, '');  // Replace commas with spaces


  const response = await fetch(`${apiUrl}/threatProtection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      email: selectedUser,
      token:keycloak.token,
      video_start_time : formattedDateTime,
  }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    alert("Processing for Threat Protection has started successfully!")
  }  

} catch (error) {
    alert("Unabled to start Threat Protection successfully. Please try again later.")
  console.error(error);
}
  };



const VideoTable = ({ videoDictionary }) => {
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>Video Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
 {Object.entries(videoDictionary).map(([videoName, options]) => {
  const displayVideoName = options.nickname && options.nickname !== "" ? options.nickname : videoName;

  return (
    <tr key={videoName}>
      <td>{displayVideoName.replace((keycloak.idTokenParsed.email + "/"), "")}</td>
<td>
  {options && options.state && options.state.Ready !== undefined
    ? options.state.Ready === 1
      ? 'Ready for Analysis'
      : 'Pending'
    : 'Ready for Analysis'}
</td>    </tr>
  );
})}
      </tbody>
    </Table>
  );
};



  return (

    <div className="dashboard-container">

    <div className="dashboard">
        {sidebarVisible && <Sidebar active="uploaded_vids" sidebar_state={sidebarVisible}/>}
      <div className="main-content">
  <Header toggleMenu={() => setSidebarVisible(!sidebarVisible) && updateDashboardMargin} />
    <div className="main-content2">
     
<Container>



<div className="videos_uploaded_container">
    <h1> Uploaded Videos </h1>

</div>

   <Row>
        {vid_arr &&       <VideoTable videoDictionary={vid_arr  } />
}

      </Row>


{admin && (

  <div>

    <h2>User Table</h2>
  <Table striped bordered hover style={{cursor:"pointer"}}>
      <thead>
        <tr>
          <th>User</th>
          <th>Videos</th>
        </tr>
      </thead>
   <tbody>
  {Object.keys(adminData).map((user) => (
    <tr key={user}>
      <td onClick={() => handleUserClick(user)}>{user}</td>
      <td>
        {selectedUser === user &&
          Object.entries(adminData[user]).map(([video, options]) => {
            const displayVideoName =
              options.nickname && options.nickname !== "" ? options.nickname : video;

            return (
              <div key={video} onClick={() => handleVideoClick(video)}>
                {displayVideoName}
              </div>
            );
          })}
      </td>
    </tr>
  ))}
</tbody>
    </Table>
<button onClick={()=> handleChangeState()} className="uploadButton"> Switch to Other Set</button> (The second table is for users without an account)

    <br/>


    {selectedUser && (
      <div>
        <h3>Selected User: {selectedUser}</h3>
{selectedVideo && (
  <p>
    Selected Video: {adminData[selectedUser][selectedVideo]?.nickname && adminData[selectedUser][selectedVideo].nickname !== ""
      ? adminData[selectedUser][selectedVideo].nickname
      : selectedVideo}
  </p>
)}
        {begin &&         <button onClick={fetchThumbnail} className="uploadButton" >
                                  Fetch Thumbnail
                  </button>}

                  <div>
                              <Form.Group controlId="formDateTime">
                                  <Form.Label>Video Start Date and Time</Form.Label>
                                  <Form.Control
                                    type="datetime-local"
                                    value={selectedDateTime}
                                    onChange={handleDateTimeChange}
                                    step="1" // This allows the input to include seconds
                                    required
                                  />
                                </Form.Group>


                  </div>
                  <br/>
                                                       <h1> Traffic Analysis</h1>
                                 <div style={{ position: 'relative' }} >
               
                    {thumbnailData && 
                    <img 
                          ref={imageRef}

                    src={thumbnailData} style={{width:'100%'}}
                     alt=""
        onClick={(e) => handleImageClick(e)}
                    />
                  }
    {<div>
                      <button onClick={handleFinalCoordinates} className="uploadButton" >
                    Confirm Boundary Coordinates
                  </button>
                  </div>}
                    <div>
        <input
          type="text"
          placeholder="Enter custom number for X"
          value={customNumberX}
          onChange={handleCustomNumberXChange}
        />
        <button onClick={handleCustomNumberXSubmit}>Select Custom Number for X</button>
      </div>

            <div>
        <input
          type="text"
          placeholder="Enter custom number for Y"
          value={customNumberY}
          onChange={handleCustomNumberYChange}
        />
       
        <button onClick={handleCustomNumberYSubmit}>Select Custom Number for Y</button>


             <p>Selected Number for X: {xx !== null ? xx : 'No number selected'}</p>
      <p>Selected Number for Y: {yy !== null ? yy : 'No number selected'}</p>
  
      </div>
    

      {finalCoordinates === true && selectedDateTime && <div>
                      <button onClick={commenceTrafficAnalysis} className="uploadButton">Start Traffic Analysis</button>
                    </div>
                  }

                <br/>

                {renderLine("normal")}
                {renderDot("normal")}
                  </div>

          <h1>  Loss Prevention </h1>
                   <div style={{ position: 'relative' }}>
       
                    {thumbnailData && 
                    <img 
                    style={{width:'100%'}}
                    src={thumbnailData}
                     alt=""
        onClick={(e) => handleLossImageClick(e)}
                    />
                  }
                      {renderLine("loss")}
                {renderDot("loss")}
                <br/>
            
                  </div>

                  {<div>
                      <button onClick={handleLossFinalCoordinates} className="uploadButton" >
                    Confirm Boundary Coordinates
                  </button>
                  </div>}


                           <div style={{ position: 'relative'}}>
                    {thumbnailData && 
                    <img 
                    src={thumbnailData}
                     alt="" style={{width:'100%'}}
        onClick={(e) => handleImageClickLoss(e)}
                    />
                  }
                  
                <br/>
                {renderPolygon()}
                {renderDot("polygon")}
                  </div>




                              {<div>
                      <button onClick={handlePolygonCoordinates} className="uploadButton" >

                    Confirm Region Coordinates
                  </button>
                  </div>}

      {lossFinalCoordinates === true && polygonCoordinates === true  && selectedDateTime && <div>
                      <button onClick={commenceLossPrevention} className="uploadButton">Start Loss Prevention</button>
                    </div>
                  }




                {<div>
             <h1>   Threat Protection</h1>
                <div>
                      <button onClick={commenceThreatProtection} className="uploadButton">Start Threat Protection Analysis</button>
                  </div>
                    </div>
              }



      </div>
    )}
  </div>
)}

   </Container>  
    </div>
               
    </div>

</div>
<Footer/>
    </div>

  );
}


export default Connect;
