import sentrihub from './assets/sentrihub.png'
import logout_png from './assets/logout.png'
import dashboard from './assets/dashboard.png'
import usersicon from './assets/settings.png'
import { useNavigate } from 'react-router-dom';
import "./CSS/sidebar.css"
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setProfileInfo } from './profileAction';

import uploadedicon from './assets/uploaded_sidebar.svg'


import { useKeycloak } from "@react-keycloak/web";


function Sidebar(active, sidebar_state, display){

  let DashboardClassName ="";
  try{
     DashboardClassName = active.active === 'dashboard' ? 'sidebar-item sidebar-item-active' : 'sidebar-item';
  }
  catch{
    DashboardClassName = "sidebar-item"
  }
  let SettingsClassName = ""
   try{
     SettingsClassName = active.active === 'settings' ? 'sidebar-item sidebar-item-active' : 'sidebar-item';
  }
  catch{
    SettingsClassName = "sidebar-item"
  }
    let uploadedVideosClassName = ""
   try{
     uploadedVideosClassName = active.active === 'uploaded_vids' ? 'sidebar-item sidebar-item-active' : 'sidebar-item';
  }
  catch{
    uploadedVideosClassName = "sidebar-item"
  }




 const navigate = useNavigate(); 
 const { keycloak } = useKeycloak();

  const [translateValue, setTranslateValue] = useState(0); // State to hold the translate value
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  }
  useEffect(() => {
  function updateSidebarHeight() {
    const headerElement = document.querySelector('.header-container');
    const footerElement = document.querySelector('.footer');
    
    if (headerElement && footerElement) {
      // Calculate the top position of the header and footer relative to the document
      const footerTop = footerElement.getBoundingClientRect().top + window.pageYOffset;
      const sidebarHeight = footerTop 
      
      // Set the height of the sidebar
      const sidebarElement = document.querySelector('.sidebar'); // Replace with your sidebar's selector
      if (sidebarElement) {
        sidebarElement.style.height = `${sidebarHeight}px`;
      }
    }
  }

  // Update the sidebar height on mount and when window is resized
  window.addEventListener('resize', updateSidebarHeight);
  updateSidebarHeight(); // Initial update on mount
  return () => {
    window.removeEventListener('resize', updateSidebarHeight);
  };
}, [scrollTop]); 


 useEffect(() => {
    window.addEventListener("scroll", onScroll);

    const headerElement = document.querySelector('.header-container');

    if (headerElement) {
      // Get the bottom position relative to the document
      var bottomPosition = headerElement.getBoundingClientRect().top + headerElement.ownerDocument.defaultView.pageYOffset;
      setTranslateValue(bottomPosition); // Set the translate value to the bottom position
    }
   

  }, [scrollTop]);

  const sidebarStyle = {
    transform: `translateY(${translateValue}px)`,
  };


  function handleLogout() {
    keycloak.logout()
  }


  
  return(
 <div className="sidebar2" >
 <div className="sidebarcontent" style={sidebarStyle}>
      <img src={sentrihub} className="sidebarLogo" alt="" />
      <br/>
   
        
  </div>
  </div>
        )
}


export default Sidebar;

