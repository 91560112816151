import React, { useState, useEffect, useRef } from 'react';
import './CSS/main_app.css'; // This imports the CSS stylesheet for this component
import { useNavigate,useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from './Footer'; // I
import Sidebar from './Sidebar'; // 
import Header from './Header'; // 
import { Dropdown, DropdownButton, Row, Col, Button, Container,ButtonGroup,Form   } from 'react-bootstrap';
import { FaCalendar } from 'react-icons/fa';
import './CSS/Dashboard.css'; // 
import { FaCloudDownloadAlt } from 'react-icons/fa';
import TrafficAnalysisTable from './TrafficAnalysisTable';
import FacialTable from './facialTable';

import {isMobile} from 'react-device-detect';
import { updateDashboardMargin } from './helperFunctions'; // Import the function
import { useKeycloak } from "@react-keycloak/web";
import axios from 'axios';
import XLSX from 'xlsx';

import { apiUrl } from './api';



function Dashboard() {
  const [vidUrl,setVidUrl] = useState('')
  const [vidStart,setVidStart] = useState('')

  const [facialData,setFacialData]= useState(null)
  const [showDates,setShowDates] = useState("")

    const [tableItemDimensions, setTableItemDimensions] = useState({ width: 0, height: 0 });

    const calculateDimensions = () => {
    const tableItem = document.querySelector('.data_table');
       if (tableItem) {
    const { width, height } = tableItem.getBoundingClientRect();
    setTableItemDimensions({ width, height });
  }
    };



    const [trafficAnalysisText, setTrafficAnalysisText] = useState('');

  function findDayWithHighestEntries(data) {
  let maxEntries = -1;
  let maxEntriesDay = null;

  for (const date in data) {
    const entries = data[date].trafficAnalysis.Total_Entries;
    if (entries > maxEntries) {
      maxEntries = entries;
      maxEntriesDay = date;
    }
  }

  return { day: maxEntriesDay, entries: maxEntries };
}
const convertTo12HourFormat2 = (dateTimeString) => {
  // Create a Date object from the given date and time string
  const date = new Date(dateTimeString);

  // Extract hour, minute, and second from the Date object
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  // Determine AM/PM indicator based on the hour value
  const amPm = hour >= 12 ? 'PM' : 'AM';

  // Convert hour to 12-hour format
  const hour12 = hour % 12 || 12;

  // Construct the 12-hour formatted time string
  const formattedTime = `${hour12}:${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second} ${amPm}`;

  return formattedTime;
};
const convertToMilliseconds = (timeString) => {
  let [time, meridiem] = timeString.split(' ');
  let [hours, minutes, seconds] = time.split(':').map(Number);

  if (meridiem === 'PM' && hours !== 12) {
    hours += 12;
  } else if (meridiem === 'AM' && hours === 12) {
    hours = 0;
  }

  return (hours * 3600 + minutes * 60 + seconds) * 1000; // Convert to milliseconds
};

const calculateTimeDifference = (vidStartTime, countTime) => {
  const vidStartTimeMillis = convertToMilliseconds(vidStartTime);
  const countTimeMillis = convertToMilliseconds(countTime);
  return countTimeMillis - vidStartTimeMillis;
};

const jumpToTimeInVideo = (video, timeDifference) => {
  // Convert time difference to seconds
  const timeDifferenceInSeconds = Math.abs(timeDifference) / 1000;
  
  // Jump to the specific time in the video
  video.currentTime = timeDifferenceInSeconds;
};


  const handleCountClick = (count) => {
    let vid_start_time = convertTo12HourFormat2(vidStart)
    const video = document.getElementById('myVideo'); // Assuming you have a <video> element with id 'myVideo'
    const timeDifference = calculateTimeDifference(vid_start_time, count);
    console.log(timeDifference)
    jumpToTimeInVideo(video, timeDifference);

  };

// Function to find the day with the lowest entries
function findDayWithLowestEntries(data) {
  let minEntries = Infinity;
  let minEntriesDay = null;

  for (const date in data) {
    const entries = data[date].trafficAnalysis.Total_Entries;
    if (entries < minEntries) {
      minEntries = entries;
      minEntriesDay = date;
    }
  }

  return { day: minEntriesDay, entries: minEntries };
}

// Function to find the day with the highest exits
function findDayWithHighestExits(data) {
  let maxExits = -1;
  let maxExitsDay = null;

  for (const date in data) {
    const exits = data[date].trafficAnalysis.Total_Exits;
    if (exits > maxExits) {
      maxExits = exits;
      maxExitsDay = date;
    }
  }

  return { day: maxExitsDay, exits: maxExits };
}

// Function to find the day with the lowest exits
function findDayWithLowestExits(data) {
  let minExits = Infinity;
  let minExitsDay = null;

  for (const date in data) {
    const exits = data[date].trafficAnalysis.Total_Exits;
    if (exits < minExits) {
      minExits = exits;
      minExitsDay = date;
    }
  }

  return { day: minExitsDay, exits: minExits };
}

// Function to find overall peak and minimal times
function findOverallPeakAndMinimalTimes(data) {
  let peakTime = null;
  let minimalTime = null;

  for (const date in data) {
    const peakHour = data[date].trafficAnalysis.Peak_Hour_Entries;
    const slowestHour = data[date].trafficAnalysis.Slowest_Hour_Entries;

    if (!peakTime || data[date].trafficAnalysis.Peak_Entries > data[peakTime].trafficAnalysis.Peak_Entries) {
      peakTime = date;
    }

    if (!minimalTime || data[date].trafficAnalysis.Slowest_Entries > data[minimalTime].trafficAnalysis.Slowest_Entries) {
      minimalTime = date;
    }
  }

  return { peakTime, minimalTime };
}

  function updateBenchmarkFromData(data) {
    const objects = Object.values(data);
    // Find the mode of Peak_Hour_Entries
    const peakHourEntriesMode = findMode(objects.map(obj => obj.trafficAnalysis.Peak_Hour_Entries));
    // Find the average of Peak_Entries for the mode
    const peakEntriesAverage = calculateAverage(objects.filter(obj => obj.trafficAnalysis.Peak_Hour_Entries === peakHourEntriesMode).map(obj => obj.trafficAnalysis.Peak_Entries));
    // Set the initial benchmark and corresponding peak_hour_entries object
    let benchmark = { value: peakEntriesAverage, peakHourEntries: peakHourEntriesMode };

    for (const obj of objects) {
        if (obj.trafficAnalysis.Peak_Hour_Entries !== benchmark.peakHourEntries) {
                   if (obj.trafficAnalysis.Peak_Entries >= 1.5 * benchmark.value) {
        benchmark = { value: obj.trafficAnalysis.Peak_Entries, peakHourEntries: obj.trafficAnalysis.Peak_Hour_Entries };
        }
        }
    }

    return benchmark;
}
 function updateBenchmarkFromData2(data) {
    const objects = Object.values(data);

    // Find the mode of Peak_Hour_Entries
    const peakHourEntriesMode = findMode(objects.map(obj => obj.trafficAnalysis.Slowest_Hour_Entries));
    // Find the average of Peak_Entries for the mode
    const peakEntriesAverage = calculateAverage(objects.filter(obj => obj.trafficAnalysis.Slowest_Hour_Entries === peakHourEntriesMode).map(obj => obj.trafficAnalysis.Slowest_Entries));
    // Set the initial benchmark and corresponding peak_hour_entries object
    let benchmark = { value: peakEntriesAverage, peakHourEntries: peakHourEntriesMode };

    for (const obj of objects) {
        if (obj.trafficAnalysis.Slowest_Hour_Entries !== benchmark.peakHourEntries) {
                   if (obj.trafficAnalysis.Slowest_Entries <= 1.5 * benchmark.value) {
        benchmark = { value: obj.trafficAnalysis.Slowest_Entries, peakHourEntries: obj.trafficAnalysis.Slowest_Hour_Entries };
        }
        }
    }

    return benchmark;
}
function convertTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(":");
    let hour = parseInt(hours);
    const suffix = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12; // Convert 0 to 12
    return `${hour}:${minutes}:${seconds} ${suffix}`;
}

// Example usage:
const time24Hour = "18:15:00";
const time12Hour = convertTo12HourFormat(time24Hour);


// Helper function to find the mode of an array

function findMode(array) {
    const counts = {};
    let maxCount = 0;
    let mode;

    for (const item of array) {
        counts[item] = (counts[item] || 0) + 1;

        if (counts[item] > maxCount) {
            maxCount = counts[item];
            mode = item;
        }
    }

    return mode;
}

// Helper function to calculate the average of an array
function calculateAverage(array) {
    return array.reduce((sum, value) => sum + value, 0) / array.length;
}

   const { keycloak } = useKeycloak();
   const [cameraAnalysisData,setCameraAnalysisData] = useState({
  "Sample Camera": {
    "11-10-2023": {
      "trafficAnalysis": {
        "Total_Male_Entries": 3,
        "Total_Female_Entries": 3,
        "Total_Adult_Entries": 3,
        "Total_Kids_Entries": 2,
        "Total_Entries": 3,
        "Total_Exits": 3, // Note: exits should be defined somewhere
        "Peak_Hour_Entries": "11 AM",
        "Slowest_Hour_Entries": "12 AM",
        "Unique_Persons_In": 2,
        "Unique_Persons_Out": 2
      }
    },
    "12-10-2023": {
      "trafficAnalysis": {
        "Total_Male_Entries": 1,
        "Total_Female_Entries": 1,
        "Total_Adult_Entries": 1,
        "Total_Kids_Entries": 2,
        "Total_Entries": 1,
        "Total_Exits": 0, // Note: exits should be defined somewhere
        "Peak_Hour_Entries": "1 AM",
        "Slowest_Hour_Entries": "2 AM",
        "Unique_Persons_In": 10,
        "Unique_Persons_Out": 10
      }
    }
  }
})
// getCameraFeedResults
  const [hasUpdatedData, setHasUpdatedData] = useState(false);
useEffect(() => {
    if (!hasUpdatedData) {
      setHasUpdatedData(true);

      async function fetchData() {
        try {
          let body = { "email": keycloak.idTokenParsed.email };
          const response = await axios.post(`${apiUrl}/getCameraFeedResults`, body);

          const newData = response.data;

          setCameraAnalysisData(newData);
        } catch (error) {
          console.error('Error fetching data from API:', error.message);
        }
      }

      fetchData();
    }
  }, [hasUpdatedData]); 

  const [selectedDates,setSelectedDates] = useState([])
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
function formatDate(date) {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
}

function createDateArray(startDate, endDate) {
  const dateArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(formatDate(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
const handleCheckboxChange = (date) => {
  setDateRange(date)
  setSelectedDates(createDateArray(date[0],date[1]))
  
};

const dates_to_show = () => {
  try {
    const dateObjects = Object.keys(cameraAnalysisData[selectedCamera]).reduce((acc, dateString) => {
      const analysisData = cameraAnalysisData[selectedCamera][dateString];
      // Check if the analysisData object has a key equal to the analysis state variable
      if (analysisData.hasOwnProperty(analysis)) {
        const [day, month, year] = dateString.split('-').map(Number);
        acc.push(new Date(year, month - 1, day));
      }
      return acc;
    }, []);
    setShowDates(dateObjects);
  } catch (error) {
    // Handle any errors if needed
    console.error("Error:", error);
  }
};



  const [userDat,setUserDat] = useState(false)
  const [fetching,setFetching] = useState(false)
  const [analyTitle,setAnalyTitle] = useState("Analysis Type")
  const [vidname,setVidname] = useState("")

  const [camera,setCamera] = useState("")
  const [datRange,setDatRange] = useState("")


  const [analname,setAnalname] = useState("")

  const [analysis, setAnalysis] = useState("")
    const {state} = useLocation();
  const {sidebar_state} = state

  const [gifData, setGifData] = useState(null)

const fetch_gif2 = async ()=>{
 
  try{
  const response = await fetch(`${apiUrl}/getGif`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
camera: selectedKey,
"email":keycloak.idTokenParsed.email
  }),
  });
  if (!response.ok) {
    await setGifData(null)
    return
  }

  const data = await response.arrayBuffer();
  
  const blob = new Blob([data], { type: 'image/gif' });
  const imageUrl = URL.createObjectURL(blob);
  await setGifData(imageUrl);
  }
  catch{
        await setGifData(null)

  }


  }

  const fetch_gif = async ()=>{
 
  try{
  const response = await fetch(`${apiUrl}/getGif`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
camera: selectedCamera,
"email":keycloak.idTokenParsed.email
  }),
  });
  if (!response.ok) {
    alert("Unabled to upload video successfully. Please try again later.")
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.arrayBuffer();
  
  const blob = new Blob([data], { type: 'image/gif' });
  const imageUrl = URL.createObjectURL(blob);
  await setGifData(imageUrl);
  }
  catch{}


  }
  const [vid_arr, setVidArr] = useState([]);
 const fetchData = async () => {
    try {
      let body = { "email": keycloak.idTokenParsed.email };
      const response = await axios.post(`${apiUrl}/getList`, body);
      const data = response.data;
      setUserDat(data)

   
  }
   catch (error) {
      console.error("Error fetching data:", error);
    }
  };
useEffect(() => {
  fetchData();
}, []);


  const vid_selector = async(option)=>{

      let updatedVidArr = [];
       Object.keys(userDat).forEach(function (key) {
        let vid = userDat[key];
        let vid_name = key;
        vid_name = vid_name.replace((keycloak.idTokenParsed.email + "/"), "");
        Object.keys(vid).forEach(function (key2) {
          if (key2 === option) {
            updatedVidArr.push({
              [vid_name]: vid[key2]
            });
          }
        });
      });

      await setVidArr(updatedVidArr);
      try{
    updatedVidArr.forEach((elem)=>{

    })
  }
  catch
  {}
}
  const [selectedCamera, setSelectedCamera] = useState(null);

  const fileInputRef = useRef();
  const [excelFile, setExcelFile] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const handleDropdownSelect = (eventKey) => {
    setDateRange([null, null])
    setSelectedDates([])
    setSelectedCamera(null)
    setExcelFile(null)
    setFacialData(null)
    setTrafficAnalysisText("")
    setSelectedKey(eventKey);
   
  };
    const handleDropdownSelectCamera = (eventKey) => {
      setSelectedDates([])
      setDateRange([null, null])
      setSelectedKey(null)
    setExcelFile(null)
    setFacialData(null)

    setTrafficAnalysisText("")
    setSelectedCamera(eventKey);   

  };
    useEffect(() => {
    // This code will run after setSelectedCamera has successfully set the state
    dates_to_show();
  }, [selectedCamera]);

  const handleGenerateAnalysis = async ()=>{
      await fetch_gif2()
      vid_arr.forEach((elem)=>{
      if (elem[selectedKey]){
         setExcelFile(elem[selectedKey]);
         setVidname(selectedKey)
         setAnalname(analysis)
         setSelectedCamera(null)
      }
    })
      await fetch_gif2()

          calculateDimensions();

}
function calculateMode(arr) {
  let modeMap = {};
  let maxCount = 0;
  let mode;


  arr.forEach((str) => {
    modeMap[str] = (modeMap[str] || 0) + 1;

    if (modeMap[str] > maxCount) {
      maxCount = modeMap[str];
      mode = str;
    }
  });

  return mode;
}

const handleGenerateAnalysis2 = async () => {
  let peak_arr = []
  let slowest_arr = []

  await fetch_gif()
  Object.keys(cameraAnalysisData).forEach((cameraKey) => {
    if (cameraKey === selectedCamera) {
      const cameraData = cameraAnalysisData[cameraKey];
      if (selectedDates.length > 0) {
      const filteredCameraData = {};
for (const dateKey in cameraData) {
  if (selectedDates.includes(dateKey)) {
    filteredCameraData[dateKey] = cameraData[dateKey];
  }
}

        let finalData = {};


        selectedDates.forEach((date) => {
          const analysisData = cameraData[date] && cameraData[date][analysis];
          if (analysisData) {
            if (analysis=="facial_info"){
              console.log(analysisData)
              let originalDictionary = analysisData
              let parsedDictionary = {
                Employees: 0,
                Visitors: originalDictionary.Visitors.length,
              };

              // Create an object to store employee information
              let employeeInfo = {};
              originalDictionary.Visitors.forEach((time, index) => {
                const visitorKey = `Visitor ${index + 1}`;
                parsedDictionary[visitorKey] = convertTo12HourFormat(time);
            });
              const vid_key = originalDictionary['object_key']
              const vid_start_time = originalDictionary['video_start_time']
              setVidStart(vid_start_time)

                fetch(`${apiUrl}/getVideoUrl/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  objectKey: vid_key // Pass the vid_key as data
                })
              })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                const videoUrl = data.url;
                setVidUrl(videoUrl)
              })
              .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
              });
              for (let employee in originalDictionary) {
                if (employee !== 'Visitors' && employee !=="object_key" && employee !=="video_start_time") {
                  parsedDictionary.Employees++;
                  let times = originalDictionary[employee].sort();

                  let earliestTime = times[0];

                  employeeInfo[employee] = convertTo12HourFormat(earliestTime);
                }
              }

              // Merge the parsed dictionary and employee information
              let resultDictionary = { ...parsedDictionary, ...employeeInfo };

              setFacialData(resultDictionary)
                 setExcelFile(finalData);
                setVidname("Camera Feed");
                setAnalname(analysis);
                setSelectedKey(null);
            }
            else{
              setFacialData(null)
                Object.keys(analysisData).forEach((property) => {
      if (property!=="Peak_Entries" && property !=="Slowest_Entries"){
        if (finalData[property] !== undefined) {
     if (!isNaN(finalData[property]) && !isNaN(analysisData[property])) {
      finalData[property] += analysisData[property];
    } else if (property === "Peak_Hour_Entries") {
      peak_arr.push(analysisData[property])
    } else if (property === "Slowest_Hour_Entries"){
      slowest_arr.push(analysisData[property])
    }else {
    }
  } else {
    finalData[property] = analysisData[property];
  }
    
    }
  
})
            }
           

       
          }
        });




        // finalData["Peak_Hour_Entries"] = calculateMode(peak_arr)
        // finalData["Slowest_Hour_Entries"] = calculateMode(slowest_arr)
        if (analysis!="facial_info"){
    const { value: newBenchmarkValue, peakHourEntries: newBenchmarkPeakHour } = updateBenchmarkFromData(filteredCameraData);
        const { value: newBenchmarkSlowValue, peakHourEntries: newBenchmarkSlowHour } = updateBenchmarkFromData2(filteredCameraData);
        finalData["Peak_Hour_Entries"] = newBenchmarkPeakHour
        finalData["Slowest_Hour_Entries"] = newBenchmarkSlowHour

        const highestEntries = findDayWithHighestEntries(filteredCameraData);
        const lowestEntries = findDayWithLowestEntries(filteredCameraData);
        const highestExits = findDayWithHighestExits(filteredCameraData);
        const lowestExits = findDayWithLowestExits(filteredCameraData);
        const overallPeakAndMinimalTimes = findOverallPeakAndMinimalTimes(filteredCameraData);

  const analysisText = `The highest number of entries was on ${highestEntries.day} with ${highestEntries.entries} people entering. 
  The lowest number of entries was on ${lowestEntries.day} with only ${lowestEntries.entries} people entering. 
  The highest number of recorded exits was ${highestExits.exits} on ${highestExits.day}, 
  while the lowest number of recorded exits was ${lowestExits.exits} on ${lowestExits.day}. 
  Overall, the hour at which traffic usually peaked was ${finalData["Peak_Hour_Entries"]}, 
  while the hour at which traffic was minimal was ${finalData["Slowest_Hour_Entries"]}.`;

        if (selectedDates.length>1){
                  setTrafficAnalysisText(analysisText)

        }
    
        setExcelFile(finalData);
        setVidname("Camera Feed");
        setAnalname(analysis);
        setSelectedKey(null);
        }
    
        


      } else {
        // Handle case where selectedDates is empty
      }
    }
  });
         await fetch_gif()

            calculateDimensions();

};


  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    setExcelFile(file);

  };

  function handleConnectClick(){
    navigate('/connect',{ state: { sidebar_state: sidebarVisible } })
  }

  const navigate = useNavigate(); 
  const [sidebarVisible, setSidebarVisible] = useState(sidebar_state);


  useEffect(() => {
  window.addEventListener('margin_updater', updateDashboardMargin);
  updateDashboardMargin(); 
  return () => {
    window.removeEventListener('margin_updater', updateDashboardMargin);
  };

}, [sidebarVisible,excelFile]); 


const handleDropdownOptionSelect =async (selectedOption) => {
// Handle the selected option here
  if (selectedOption=="Customer Traffic"){
    await setAnalysis("trafficAnalysis")
    await setAnalyTitle("Customer Traffic")
    await vid_selector("trafficAnalysis")
  }
  else if (selectedOption=="Onsite Checkin"){
    await setAnalysis("facial_info")
    await setAnalyTitle("Onsite Checkin")
    await vid_selector("facial_info")
  }
  else if (selectedOption=="Theft Detection"){
    await setAnalysis("lossPrevention")
    await setAnalyTitle("Theft Detection")
    await vid_selector("lossPrevention")
  }
  else if (selectedOption=="Threat Detection"){
    await setAnalysis("threatProtection")
    await setAnalyTitle("Threat Detection")
    await vid_selector("threatProtection")
  }

  await setDateRange([null, null])
   await setSelectedDates([])
    await setSelectedCamera(null)
    await setExcelFile(null)
    await setFacialData(null)

    await setTrafficAnalysisText("")
    await setSelectedKey(null);
    await setGifData(null)
    await setVidUrl('')
    await setVidStart('')

  
};

  const options = ['Customer Traffic','Theft Detection','Threat Detection','Onsite Checkin'];

  return (
    <div className="dashboard-container">


    <div className="dashboard">
        {sidebarVisible && <Sidebar />}

      <div className="main-content">
  <Header toggleMenu={() => setSidebarVisible(!sidebarVisible) && updateDashboardMargin} />
<div className="main-content2">

<div className="tpage_title">
    <h1>Video Analysis</h1>
</div>

    </div>

<div className="table_button_group_container">
<div className ="table_button_group">
   <DropdownButton
      title={
        <>
          {analyTitle}
        </>
      }
      variant="outline-secondary"
      id="type-dropdown"
      className="dropdown_new"
      onSelect={handleDropdownOptionSelect}
    >
      {options.length > 0 ? (
        options.map((option, index) => (
          <Dropdown.Item key={index} eventKey={option}>
            {option}
          </Dropdown.Item>
        ))
      ) : (
        <Dropdown.Item disabled>No options available</Dropdown.Item>
      )}
    </DropdownButton>





    <DropdownButton
  title={
    <>
      <FaCalendar className="calendar-icon" /> {selectedKey === null
        ? "Video"
        : (userDat[selectedKey]?.nickname && userDat[selectedKey]?.nickname !== ""
          ? userDat[selectedKey].nickname
          : selectedKey)}
    </>
  }
  variant="outline-secondary"
  id="date-dropdown"
  className="dropdown_new" 
  onSelect={handleDropdownSelect}
>{vid_arr.length > 0 ? (
  vid_arr.map((item, index) => {
    const videoKey = Object.keys(item)[0];
    const nickname = userDat[videoKey]?.nickname;

    return (
      <Dropdown.Item key={index} eventKey={videoKey} onSelect={() => handleDropdownSelect(videoKey)}>
        {nickname && nickname !== "" ? nickname : videoKey}
      </Dropdown.Item>
    );
  })
) : (
  <Dropdown.Item disabled>No options available</Dropdown.Item>
)}
   
         </DropdownButton>




{!isMobile && <DropdownButton
  title={
    <>
      <FaCalendar className="calendar-icon" /> {selectedCamera === null
        ? "Camera"
        : selectedCamera}
    </>
  }
  variant="outline-secondary"
  id="date-dropdow"
  className="dropdown_new"
  onSelect={handleDropdownSelectCamera}
>


{Object.keys(cameraAnalysisData).length > 0 ? (
  Object.keys(cameraAnalysisData).map((cameraKey, index) => (
    <Dropdown.Item key={index} eventKey={cameraKey} onSelect={() => handleDropdownSelectCamera(cameraKey)}>
      {cameraKey}
    </Dropdown.Item>
  ))
) : (
  <Dropdown.Item disabled>No options available</Dropdown.Item>
)}




</DropdownButton>

}
  {selectedCamera && !isMobile && (
  <DatePicker
    selectsRange={true}
    startDate={startDate}
    endDate={endDate}
    onChange={handleCheckboxChange}
    isClearable={true}
    placeholderText="Select Dates"
    includeDates={showDates}
    
  />
      )}


   

      {/*  <Button className="download_data_button" disabled>
      <FaCloudDownloadAlt className="" />
    </Button>*/
    }
    


   
      {!isMobile && <Button onClick={handleConnectClick} className="uploadButton_new2" style={{marginLeft:"auto"}}>
      Upload New Video
    </Button>}
      </div>

<div className ="table_button_group">


{isMobile && <DropdownButton
  title={
    <>
      <FaCalendar className="calendar-icon" /> {selectedCamera === null
        ? "Camera"
        : selectedCamera}
    </>
  }
  variant="outline-secondary"
  id="date-dropdow"
  className="dropdown_new"
  onSelect={handleDropdownSelectCamera}
>
{analysis !== "" && Object.keys(cameraAnalysisData).length > 0 ? (
  Object.keys(cameraAnalysisData).map((cameraKey, index) => (
    <Dropdown.Item key={index} eventKey={cameraKey} onSelect={() => handleDropdownSelectCamera(cameraKey)}>
      {cameraKey}
    </Dropdown.Item>
  ))
) : (
  <Dropdown.Item disabled>No options available</Dropdown.Item>
)}

</DropdownButton>
}
  {selectedCamera && isMobile && (
        <div>
  
    <DatePicker
    selectsRange={true}
    startDate={startDate}
    endDate={endDate}
    onChange={handleCheckboxChange}
    isClearable={true}
    placeholderText="Select Dates"
    includeDates={showDates}
    
  />

          {/* Display the selected dates */}
         
        </div>
      )}


</div>
 <div style={{display:"flex",justifyContent:"space-between"}}>

{selectedKey === null && selectedDates.length === 0 && (
  <Button className="uploadButton_new2" disabled>
    Generate Analysis
  </Button>
)}



   {selectedKey && <Button onClick={handleGenerateAnalysis} className="uploadButton_new2">
      Generate Analysis
    </Button>}
{selectedCamera && selectedDates.length !== 0 && (
  <Button onClick={handleGenerateAnalysis2} className="uploadButton_new2">
    Generate Analysis
  </Button>
)}

      {isMobile && <Button onClick={handleConnectClick} className="uploadButton_new2" style={{marginLeft:"auto"}}>
      Upload New Video
    </Button>}
    </div>
        </div>



    

          {/* Render the TrafficAnalysisTable with the uploaded Excel file */}

     {!isMobile && (
  <div className="handle_table" style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
  <div className="tableItem" style= {{width:"100%",position:"relative",display:"flex",alignItems:"center",justifyContent:"center"}}>
  <div className="x" style={{width:"50%"}}>
    {excelFile && analname && vidname && !facialData && (
      <TrafficAnalysisTable 
        json={{
          excelFile,
          analysis: analname,
          key: userDat[selectedKey]?.nickname && userDat[selectedKey]?.nickname !== ""
            ? userDat[selectedKey].nickname
            : vidname
        }}
      />
    )}
    </div>
  
     {excelFile && gifData && !facialData && <img src={gifData} style={{width:"30%"}}/>}       
  </div>
  </div>

)}
     {!isMobile && (
  <div className="handle_table" style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
  <div className="tableItem" style= {{width:"100%",position:"relative",display:"flex",alignItems:"center",justifyContent:"center"}}>
  <div className="x" style={{width:"50%"}}>
    {analname && vidname && facialData!==null && (

      <FacialTable 
        json={{
          excelFile: facialData,
          analysis: analname,
          key: "Additional Information",
        }}
        onCountClick={handleCountClick}
      />
    )}
         

    </div>
  {analname && vidname && facialData!==null && vidUrl!=='' &&  <video id="myVideo" style={{width:'30%'}} controls>
        <source src={vidUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    }       

  </div>
  </div>

)}

{isMobile && (
  <div className="handle_table">

    {analname && vidname && facialData!==null && vidUrl!=='' &&  <video id="myVideo" style={{maxWidth:'100%'}} controls>
        <source src={vidUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    }       

       {excelFile && gifData && !facialData && <img src={gifData} style={{paddingTop:"50px",paddingLeft:"50px",paddingRight:"50px"}}/>}       


  {analname && vidname && facialData!==null && (

      <FacialTable 
        json={{
          excelFile: facialData,
          analysis: analname,
          key: "Additional Information",
        }}
        onCountClick={handleCountClick}
      />
    )}

    {excelFile && analname && vidname && !facialData && (
      <TrafficAnalysisTable
        json={{
          excelFile,
          analysis: analname,
          key: userDat[selectedKey]?.nickname && userDat[selectedKey]?.nickname !== ""
            ? userDat[selectedKey].nickname
            : vidname
        }}
      />

    )}

  </div>

)}

{
  excelFile 
  && selectedCamera 
  && selectedDates 
  && !facialData
  && (
    <p style={{
      paddingBottom: "50px",
      paddingLeft: "10px",
      paddingRight: "10px",
        // Example background color
      color: "#333",               // Example text color
      borderRadius: "8px",         // Example border radius
      textAlign: "center",
      fontSize:"25px"          // Example text alignment
    }}>
      {trafficAnalysisText}
    </p>
  )
}




          
    </div>


</div>
<Footer/>
    </div>

  );
}

export default Dashboard;
