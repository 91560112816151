// profileReducer.js
const initialState = {
  profileInfo: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROFILE_INFO':
      return {
        ...state,
        profileInfo: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        profileInfo: null,
      };
    default:
      return state;
  }
};

export default profileReducer;
