import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './CSS/normal.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './CSS/App.css'
import Dashboard from './Dashboard';
import TrafficAnalysis from './TrafficAnalysis';
import LossPrevention from './LossPrevention';
import ThreatProtection from './ThreatProtection';
import Users from './Users'
import Connect from './connect'
import Login from './Login'
import VideoAnalysis from './video_analysis';
import UploadedVideos from './uploadedVideos';
import UploadNoLogin from './uploadNoLogin';

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak"


function App() {

  return (
    <ReactKeycloakProvider authClient={keycloak}>
    <div className="app-container">
        <Router>
      <Routes>
      <Route path="/" element = {<Login/>} / >  
    <Route path="uploadVideo" element={<UploadNoLogin />} />

        <Route path="dashboard" element={<Dashboard />} />
        <Route path="traffic-analysis" element={<TrafficAnalysis />} />
        <Route path="loss-prevention" element={<LossPrevention />} />
        <Route path="threat-protection" element={<ThreatProtection />} />
        <Route path="/users" element={<Users />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/video_analysis" element={<VideoAnalysis />} />
        <Route path="/uploaded_videos" element={<UploadedVideos />} />

      </Routes>
    </Router>

    </div>
</ReactKeycloakProvider>
  );
};


export default App
