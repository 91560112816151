import React, { useState, useEffect, useRef } from 'react';
import './CSS/main_app.css'; // This imports the CSS stylesheet for this component
import Footer from './Footer'; // I
import Sidebar from './Sidebar'; // 
import Header from './Header'; // 
import { Container, Row, Col, Button, ProgressBar, ButtonGroup,Form  } from 'react-bootstrap';
import { apiUrl } from './api';
import dummy_img from './123.png'
import './CSS/Dashboard.css'; // 
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';

import {isMobile} from 'react-device-detect';

import { updateDashboardMargin, CameraForm } from './helperFunctions'; // Import the function
import './CSS/Users.css'


import { useKeycloak } from "@react-keycloak/web";


import image1 from './assets/hikvision_logo.png'; // Replace with the actual path to your images
import image2 from './assets/ezviz_logo.avif';



const adjustElementMinHeight = () => {
  console.log("C");

  const cards2 = document.querySelectorAll('.container');
  const cards = document.querySelectorAll('.main-content2');
  const header = document.querySelector('.header'); // replace with your header class or ID
  const footer = document.querySelector('.footer'); // replace with your footer class or ID

  if (cards.length > 0 && header && footer) {
    let headerHeight = header.offsetHeight;
    let footerHeight = footer.offsetHeight;  
    cards.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight}px)`;
    });
      cards2.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight+headerHeight}px)`;
      card.style.marginBottom = `calc(${footerHeight}px)`;
    });
  }
  };



function Connect() {
        useEffect(() => {
    // Run the function on component mount
    adjustElementMinHeight();

    // Add event listener for window resize
    window.addEventListener('resize', adjustElementMinHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustElementMinHeight);
    };
  }, []); // Empty dependency array means it will only run once on mount
    const { keycloak } = useKeycloak();


  const [lossPreventionChecked, setLossPreventionChecked] = useState(false);
  const [trafficAnalysisChecked, setTrafficAnalysisChecked] = useState(false);
  const [threatProtectionChecked, setThreatProtectionChecked] = useState(false);

 if (keycloak.authenticated){
  let body = {"email":keycloak.idTokenParsed.email}
  axios.post(`${apiUrl}/getDict`,body)
        .then((response) => {
          setLossPreventionChecked(response.data.lossPrevention)
          setTrafficAnalysisChecked(response.data.trafficAnalysis)
          setThreatProtectionChecked(response.data.threatProtection)

          })
        .catch((error) => console.log(error));
    }


    const {state} = useLocation();
  const {sidebar_state} = state


const [startTime,setStartTime] = useState(null);
const [startCoordinate, setStartCoordinate] = useState(null);
  const [endCoordinate, setEndCoordinate] = useState(null);
const [lossStartCoordinate, setLossStartCoordinate] = useState(null);
  const [lossEndCoordinate, setLossEndCoordinate] = useState(null);

  const [firstCoordinate, setFirstCoordinate] = useState(null);
const [fourthCoordinate, setFourthCoordinate] = useState(null);
const [secondCoordinate, setSecondCoordinate] = useState(null);
  const [thirdCoordinate, setThirdCoordinate] = useState(null);
    const imageRef = useRef(null);

 const renderPolygon = () => {
  if (firstCoordinate && secondCoordinate && thirdCoordinate && fourthCoordinate) {
      console.log("Y")
         const a= drawLine(firstCoordinate,secondCoordinate)
         const b =  drawLine(secondCoordinate,thirdCoordinate)
         const c = drawLine(thirdCoordinate,fourthCoordinate)
         const d =  drawLine(fourthCoordinate,firstCoordinate)
        return (
          <div>
          {a}
          {b}
          {c}
          {d}
          </div>)


  }

  return null;
};

const handleImageClickLoss = (e) =>{

      const xCoordinate = e.nativeEvent.offsetX;
    const yCoordinate = e.nativeEvent.offsetY;


if (!firstCoordinate && !secondCoordinate && !thirdCoordinate && !fourthCoordinate){
  setFirstCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && !secondCoordinate && !thirdCoordinate && !fourthCoordinate){
  setSecondCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && secondCoordinate && !thirdCoordinate && !fourthCoordinate){
  setThirdCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && secondCoordinate && thirdCoordinate && !fourthCoordinate){
  setFourthCoordinate({ x: xCoordinate, y: yCoordinate })
}
if (firstCoordinate && secondCoordinate && thirdCoordinate && fourthCoordinate){
  setFirstCoordinate({ x: xCoordinate, y: yCoordinate })
  setSecondCoordinate(null)
  setThirdCoordinate(null)
  setFourthCoordinate(null)
}
}
  const handleImageClick = (e) => {

     const xCoordinate = e.nativeEvent.offsetX;
    const yCoordinate = e.nativeEvent.offsetY;

    if (startCoordinate && endCoordinate){
     
      setStartCoordinate({ x: xCoordinate, y: yCoordinate });
      setEndCoordinate(null)
    }
    else if (!startCoordinate) {
      // Set the starting point
      setStartCoordinate({ x: xCoordinate, y: yCoordinate });
    } else {
      // Set the ending point and draw the line
  
      setEndCoordinate({ x: xCoordinate, y: yCoordinate });
      // You can now use these coordinates to draw lines or send them to someone
    }
  };
    const handleLossImageClick = (e) => {

     const xCoordinate = e.nativeEvent.offsetX;
    const yCoordinate = e.nativeEvent.offsetY;

    if (lossStartCoordinate && lossEndCoordinate){
     
      setLossStartCoordinate({ x: xCoordinate, y: yCoordinate });
      setLossEndCoordinate(null)
    }
    else if (!lossStartCoordinate) {
      // Set the starting point
      setLossStartCoordinate({ x: xCoordinate, y: yCoordinate });
    } else {
      // Set the ending point and draw the line
  
      setLossEndCoordinate({ x: xCoordinate, y: yCoordinate });
      // You can now use these coordinates to draw lines or send them to someone
    }
  };
   const renderDot = (pass_var) => {
  const getDotStyle = (x, y, rotate = 0) => ({
    position: 'absolute',
    top: y - 2.5,
    left: x - 2.5,
    width: 10,
    height: 10,
    backgroundColor:'rgb(200, 0, 4,1.0)'
  });
  var [startX, startY] = [null,null]
  var [endX, endY] = [null,null]
  var [secondX,secondY] =[null,null]
  var [thirdX,thirdY] = [null,null]
  if (pass_var=="normal"){
    if (startCoordinate){
       [startX, startY] = [startCoordinate.x, startCoordinate.y];
    }
    if (endCoordinate){
       [endX, endY] = [endCoordinate.x, endCoordinate.y];
    }
    return (
      <>
        {startCoordinate && <div className="dot" style={getDotStyle(startX, startY)} />}
        {endCoordinate && <div className="dot" style={getDotStyle(endX, endY)} />}
      </>
    );
    }
 

   else if (pass_var=="loss"){

    if (lossStartCoordinate){
       [startX, startY] = [lossStartCoordinate.x, lossStartCoordinate.y];
    }
    if (lossEndCoordinate){
       [endX, endY] = [lossEndCoordinate.x, lossEndCoordinate.y];
    }
    return (
      <>
        {lossStartCoordinate && <div className="dot" style={getDotStyle(startX, startY)} />}
        {lossEndCoordinate && <div className="dot" style={getDotStyle(endX, endY)} />}
      </>
    );
    }


  else if (pass_var=="polygon"){
       if (firstCoordinate){
       [startX, startY] = [firstCoordinate.x, firstCoordinate.y];
    }
    if (fourthCoordinate){
       [endX, endY] = [fourthCoordinate.x, fourthCoordinate.y];
    }
     if (thirdCoordinate){
       [thirdX, thirdY] = [thirdCoordinate.x, thirdCoordinate.y];
    }
       if (secondCoordinate){
       [secondX, secondY] = [secondCoordinate.x, secondCoordinate.y];
    }

    return (
      <>

        {firstCoordinate && <div className="dot" style={getDotStyle(startX, startY)} />}
        {fourthCoordinate && <div className="dot" style={getDotStyle(endX, endY)} />}
        {secondCoordinate && <div className="dot" style={getDotStyle(secondX, secondY)} />}
        {thirdCoordinate && <div className="dot" style={getDotStyle(thirdX, thirdY)} />}
      </>
    );
  }

  return null;
};

const drawLine = (startCoord,endCoord) => {
  const [startX, startY] = [startCoord.x, startCoord.y];
  const [endX, endY] = [endCoord.x, endCoord.y];
  const length = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
  const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI);

  const lineStyle = {
    position: 'absolute',
    top: startY,
    left: startX,
    width: length,
    transform: `rotate(${angle}deg)`,
    transformOrigin: 'top left',
    borderBottom: '5px solid rgb(200, 0, 4,1.0)',
    position: 'absolute',
  };

  return (
    <div className="line" style={{position:'absolute',
    top:startY,
    left:startX,
    width:length,
    transform: `rotate(${angle}deg)`,
    transformOrigin: 'top left', // Set transform origin for correct rotation
    borderBottom: '5px solid rgb(200, 0, 4,1.0)',
    position: 'absolute',}} />
  );
};

   const renderLine = (line_var) => {
    if (line_var!="loss"){
    if (startCoordinate && endCoordinate){
     return drawLine(startCoordinate,endCoordinate)
    }
    }
    if (lossStartCoordinate && lossEndCoordinate){
     return drawLine(lossStartCoordinate,lossEndCoordinate)
    }

    
    return null;
  };

  const videoRef = useRef(null);
   
  const [sidebarVisible, setSidebarVisible] = useState(sidebar_state);
    useEffect(() => {
  window.addEventListener('margin_updater', updateDashboardMargin);
  updateDashboardMargin(); // Initial update on mount
  return () => {
    window.removeEventListener('margin_updater', updateDashboardMargin);
  };
}, [sidebarVisible]); 



   const [EZVIZ, setEZVIZ] = useState(false);
  const [HIKVISION, setHIKVISION] = useState(false);
  const [Other, setOther] = useState(false);

  const toRender = ()=>{
// export function CameraForm({ cameraID, emailID, cameraPassword, password, url }) {

   let hikvision = CameraForm({
  url:'1',
  emailID: '1',
  password: '1',
  state: 'hikvision'
})

   let other = CameraForm({
  url:'1',
  emailID: '1',
  password: '1',
  state: 'other'
})
   

   let ezviz = CameraForm({
  emailID: '1',
  password: '1',
  cameraID:'1',
  cameraPassword:'1',
  state : 'ezviz',
})
   if (EZVIZ){
    return ezviz
   }
   else if (Other){
    return other
   }
   else if (HIKVISION){
    return hikvision
   }
  }
   const handleButtonClick = (buttonName) => {

    switch (buttonName) {
      case 'EZVIZ':
        setEZVIZ(!EZVIZ);
        setHIKVISION(false)
        setOther(false)
        break;
      case 'HIKVISION':
        setHIKVISION(!HIKVISION);
        setOther(false)
        setEZVIZ(false)
        break;
      case 'Other':
        setOther(!Other);
        setHIKVISION(false)
        setEZVIZ(false)
        break;
      default:
        break;
    }
  };



  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [thumbnailData, setThumbnailData] = useState(null);
  const [trafficReady, setTrafficReady] = useState(null);
  const [finalCoordinates,setFinalCoordinates] = useState(null)
  const [lossFinalCoordinates,setLossFinalCoordinates] = useState(null)
  const [polygonCoordinates,setPolygonCoordinates] = useState(null)
  const chunkSize = 5 * 1024 * 1024; // 5 MB
  const totalChunks = file ? Math.ceil(file.size / chunkSize) : 0;


  const scaled_coord_calc = (old_obj) =>{
    const x = old_obj['x']
    const y = old_obj['y']
     let offsetwidth = imageRef.current.offsetWidth
    let offsetheight = imageRef.current.offsetHeight
    let naturalheight = imageRef.current.naturalHeight
    let naturalwidth = imageRef.current.naturalWidth

    const scaling_x = naturalwidth/offsetwidth
    const scaling_y = naturalheight/offsetheight
 
    const new_x= Math.floor(x*scaling_x)
    const new_y =Math.floor(y*scaling_y)
    const obj = {'x':new_x,'y':new_y}

    return obj
  }

  const handleFinalCoordinates = async ()=>{
   
    await setFinalCoordinates(true)
    alert("The coordinates have been confirmed.")
  }
    const handleLossFinalCoordinates = async ()=>{
   
    await setLossFinalCoordinates(true)
    alert("The coordinates have been confirmed.")
  }

    const handlePolygonCoordinates = async ()=>{
    setPolygonCoordinates(true)
    alert("The coordinates have been confirmed.")
  }
  const uploadFile = async (file) => {
    setUploading(true);
    let extension_name = keycloak.idTokenParsed.email +"/"+file.name
    const startResponse = await fetch(`${apiUrl}/start-upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileName: extension_name })
    });
    const { uploadId } = await startResponse.json();
    
    let parts = [];
    for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
      const start = (partNumber - 1) * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      let extension_name = keycloak.idTokenParsed.email +"/"+file.name

      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('fileName', extension_name);
      formData.append('partNumber', partNumber);
      formData.append('uploadId', uploadId);

      const uploadResponse = await fetch(`${apiUrl}/upload-part`, {
        method: 'POST',
        body: formData
      });
      const { ETag } = await uploadResponse.json();
      parts.push({ ETag, PartNumber: partNumber });
      setUploadProgress((partNumber / totalChunks) * 100);
    }
    extension_name = keycloak.idTokenParsed.email +"/"+file.name

try {
    //  const formattedDateTime = new Date(selectedDateTime)
    // .toLocaleString('en-GB', {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    // })
    // .replace(/\//g, '-').replace(/,/g, '');  // Replace commas with spaces



  const response = await fetch(`${apiUrl}/complete-upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      uploadId,
      parts,
      email: keycloak.idTokenParsed.email,
      nickname:nickName,
  }),
  });

  if (!response.ok) {
    alert("Unabled to upload video successfully. Please try again later.")
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

} catch (error) {
  alert("Unabled to upload video successfully. Please try again later.")
  console.error('Error fetching thumbnail:', error);
}
    setUploading(false);
  };

async function handleFileChange(event){
  setFile(event.target.files[0]);
  const file = event.target.files[0];

};

  const handleUpload = () => {
    if (file) {
      uploadFile(file);
    }
  };
     function getUserDateTime() {
        // Get date and time input from the user
        let userInput = prompt("Please enter a date and time (DD-MM-YYYY HH:mm:ss):");
        setStartTime(userInput)
        alert(userInput)
    }

  useEffect(() => {
    window.addEventListener('margin_updater', updateDashboardMargin);
    updateDashboardMargin();
    return () => {
      window.removeEventListener('margin_updater', updateDashboardMargin);
    };
  }, []);



  const commenceTrafficAnalysis = async () => {
    let extension_name = keycloak.idTokenParsed.email +"/"+file.name
  
try {
  const new_start = scaled_coord_calc(startCoordinate)
  const new_end = scaled_coord_calc(endCoordinate)

  const response = await fetch(`${apiUrl}/trafficAnalysis`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      email: keycloak.idTokenParsed.email,
      token:keycloak.token,
      line_start : [new_start['x'],new_start['y']],
      line_end : [new_end['x'],new_end['y']],
      video_start_time : startTime,
  }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    alert("Processing for Traffic Analysis has started successfully!")
  }  

} catch (error) {
    alert("Unabled to start Traffic Analysis successfully. Please try again later.")
  console.error(error);
}
  };



   const commenceLossPrevention = async () => {
    let extension_name = keycloak.idTokenParsed.email +"/"+file.name
  
try {
    const new_start = scaled_coord_calc(lossStartCoordinate)
  const new_end = scaled_coord_calc(lossEndCoordinate)

    const newfirst = scaled_coord_calc(firstCoordinate)
  const newsecond = scaled_coord_calc(secondCoordinate)
    const newthird = scaled_coord_calc(thirdCoordinate)
  const newfourth = scaled_coord_calc(fourthCoordinate)

  const response = await fetch(`${apiUrl}/lossPrevention`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      email: keycloak.idTokenParsed.email,
      token:keycloak.token,
      line_start : [new_start['x'],new_start['y']],
      line_end : [new_end['x'],new_end['y']],
      firstCoordinate:[newfirst['x'],newfirst['y']],
      secondCoordinate: [newsecond['x'],newsecond['y']],
      thirdCoordinate:[newthird['x'],newthird['y']],
      fourthCoordinate:[newfourth['x'],newfourth['y']],
      video_start_time : startTime,
  }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    alert("Processing for Loss Prevention has started successfully!")
  }  

} catch (error) {
    alert("Unabled to start Traffic Analysis successfully. Please try again later.")
  console.error(error);
}
  };


const commenceThreatProtection = async () => {
    let extension_name = keycloak.idTokenParsed.email +"/"+file.name
  
try {
  const response = await fetch(`${apiUrl}/threatProtection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: extension_name,
      email: keycloak.idTokenParsed.email,
      token:keycloak.token,
      video_start_time : startTime,
  }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    alert("Processing for Threat Protection has started successfully!")
  }  

} catch (error) {
    alert("Unabled to start Threat Protection successfully. Please try again later.")
  console.error(error);
}
  };


  const [selectedDateTime, setSelectedDateTime] = useState('');

  const handleDateTimeChange = (event) => {
    const dateTimeValue = event.target.value;
  
    setSelectedDateTime(dateTimeValue);
  };
  const isUploadButtonDisabled = !file
  const [nickName, setNickName] = useState("");

async function handleNickName(event){
 const textValue = event.target.value;
  setNickName(textValue);
};



  return (

    <div className="dashboard-container">

    <div className="dashboard">
        {sidebarVisible && <Sidebar sidebar_state={sidebarVisible}/>}
      <div className="main-content">
  <Header toggleMenu={() => setSidebarVisible(!sidebarVisible) && updateDashboardMargin} />
    <div className="main-content2">
     
<Container>
      <Row className="mb-3 w-100" >
        <Col>
          <h1>Camera Connection</h1>

          <b> Choose the camera type for connection.</b>
          <br/>
          <b> You will be directed to the camera login portal where you are required to input your designated username and password.</b>
        </Col>
      </Row>

<Row>

    <Col md={3} className="cameraButtons">
          <Button variant={HIKVISION ? 'secondary' : 'outline-secondary'} className="h-100 w-100" size="lg"
          onClick={() => handleButtonClick('HIKVISION')}>
            <img
              src={image1}
              alt="Button 1"
              className="img-fluid"
            />
          </Button>
      
       </Col>
       <Col md={3} className="cameraButtons">
          <Button variant={EZVIZ ? 'secondary' : 'outline-secondary'} className="w-100 h-100" size = "lg"
            onClick={() => handleButtonClick('EZVIZ')}>
            <img
              src={image2}
              alt="Button 2"
              className="img-fluid"
            />
          </Button>
       </Col>
       <Col md={3} className="cameraButtons">
          <Button variant={Other ? 'secondary' : 'outline-secondary'} className="w-100 h-100" size="lg"
          onClick={() => handleButtonClick('Other')}>
           Other
          </Button>
      </Col>
    {toRender()}
  </Row>
  <hr/>
            

            <Row className="mb-3">
              <Col>
                <h1>Upload Video</h1>
                <p>
                Upload videos for only one day at a time.</p>
                <div className='mt-4'>
                <div className="choose_and_upload">
              <Form style={{minWidth:"70%",backgroundColor:"transparent",boxShadow:"none"}}>
                                <Form.Group controlId="formFile">
                                  <Form.Label>Upload File</Form.Label>
                                  <Form.Control
                                    type="file"
                                    onChange={handleFileChange}
                                    disabled={uploading}
                                    required
                                  />

                                </Form.Group>


                                   <Form.Group controlId="formFile">
                                  <Form.Label>Nickname (Optional)</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Nickname for File (Optional)"
                                    onChange={handleNickName}
                                    disabled={uploading}
                                  />


                                </Form.Group>


                             {/*  <Form.Group controlId="formDateTime">
                                  <Form.Label>Date and Time</Form.Label>
                                  <Form.Control
                                    type="datetime-local"
                                    value={selectedDateTime}
                                    onChange={handleDateTimeChange}
                                    step="1" // This allows the input to include seconds
                                    required
                                  />
                                </Form.Group>*/}




              </Form>



              

           </div>
<div style={{paddingLeft:"15px"}}>
        <button
          onClick={handleUpload}
          disabled={uploading || isUploadButtonDisabled}
          className="uploadButton9"
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
        </div>



                <br/>
                  {uploading && <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} />}

                  {uploadProgress === 100 && !uploading && <div>Upload Complete! Note: Processing a 24-hour video may take up to an hour. For current status, please click on the Uploaded Videos Tab on the Side Bar. </div>}

{/*                  
                  {uploadProgress === 100 && !uploading && trafficAnalysisChecked &&<div>
                                                          <h1>Traffic Analysis</h1>

Upload complete! 
<br/>
<br/>
To initiate analysis:
<br/><br/>
1. Click on two points to define a boundary for entry and exit
(ie the left and right side of the door frame)
<br/>
2. Once you have selected your points, click “Confirm
boundary coordinates” to confirm
<br/>
3. If you make a mistake and need to start over, just click on
a new point on the screen and the line-drawing process will
start over
<br/>
4. When you have selected and confirmed your boundary
points, choose the date and time at which the video starts
<br/>
5. Then, click on “Start Traffic Analysis” to start your analysis!
Note: Processing a 24-hour video may take up to an hour
  <div style={{ position: 'relative' }} >
                    {thumbnailData && 
                    <img 
                          ref={imageRef}

                    src={thumbnailData} style={{width:'100%'}}
                     alt=""
        onClick={(e) => handleImageClick(e)}
                    />
                  }

                <br/>

                {renderLine("normal")}
                {renderDot("normal")}


                  </div>
</div>
}
<br/>
                  
                
                </div>

                <br/>
                  {uploadProgress === 100 && !uploading && trafficAnalysisChecked &&<div>
                      <button onClick={handleFinalCoordinates} disabled={uploading} className="uploadButton" >

                    Confirm Boundary Coordinates
                  </button>
                  </div>}

                  <br/>
               {uploadProgress === 100 && !uploading && trafficAnalysisChecked &&<div>
                      <button onClick={getUserDateTime} className="uploadButton">Enter Date and Time</button>
                    </div>
              }

                       <br/>
                  {finalCoordinates === true && startTime && trafficAnalysisChecked && <div>
                      <button onClick={commenceTrafficAnalysis} className="uploadButton">Start Traffic Analysis</button>
                    </div>
                  }

                  <br/>



                    {uploadProgress === 100 && !uploading && lossPreventionChecked &&<div>
                                                          <h1>Loss Prevention</h1>

Upload complete! 
<br/>
<br/>
To initiate analysis:
<br/><br/>
1. Click on two points to define a boundary for entry and exit
(ie the left and right side of the door frame)
<br/>
2. Once you have selected your points, click “Confirm
boundary coordinates” to confirm
<br/>
3. If you make a mistake and need to start over, just click on
a new point on the screen and the line-drawing process will
start over
<br/>
4. When you have selected and confirmed your boundary
points, choose the date and time at which the video starts
<br/>
                 <div style={{ position: 'relative' }}>
                    {thumbnailData && 
                    <img 
                    style={{width:'100%'}}
                    src={thumbnailData}
                     alt=""
        onClick={(e) => handleLossImageClick(e)}
                    />
                  }
                      {renderLine("loss")}
                {renderDot("loss")}
                <br/>
            
                  </div>
                                           </div>}
<br/>



          <br/>
{uploadProgress === 100 && !uploading && lossPreventionChecked &&<div>
                      <button onClick={lossFinalCoordinates} disabled={uploading} className="uploadButton" >
                    Confirm Boundary Coordinates
                  </button>
                  </div>}

                  <br/>
               {uploadProgress === 100 && !uploading && lossPreventionChecked &&<div>
                      <button onClick={getUserDateTime} className="uploadButton">Enter Date and Time</button>
                    </div>
              }

              {uploadProgress === 100 && !uploading && lossPreventionChecked &&<div>
              <br/>
              <div>
5. Next, click four points to draw a four-sided shape. This will be
the area of the video that the AI engine will analyze for
crowding.
<br/>
6. When you have drawn your shape, click “Confirm Region
Coordinates” to confirm <br/>
7. Then, click on “Start Loss Prevention” to start your analysis!
              </div>
                <div style={{ position: 'relative'}}>
                    {thumbnailData && 
                    <img 
                    src={thumbnailData}
                     alt="" style={{width:'100%'}}
        onClick={(e) => handleImageClickLoss(e)}
                    />
                  }
                  
                <br/>
                {renderPolygon()}
                {renderDot("polygon")}
                  </div>

              </div>}
              <br/>
                              {uploadProgress === 100 && !uploading && lossPreventionChecked &&<div>
                      <button onClick={handlePolygonCoordinates} disabled={uploading} className="uploadButton" >

                    Confirm Region Coordinates
                  </button>
                  </div>}

                       <br/>
                  {lossFinalCoordinates === true && polygonCoordinates === true && startTime && lossPreventionChecked && <div>
                      <button onClick={commenceLossPrevention} className="uploadButton">Start Loss Prevention</button>
                    </div>
                  }

                  <br/>



                  {uploadProgress === 100 && !uploading && threatProtectionChecked && 
                  <div>
                  <h1>Threat Protection</h1>

                  <div>
Upload complete! <br/>
<br/>
To initiate analysis: <br/><br/>
1. Choose the date and time when you would like the video
analysis to start<br/>
2. Then, click on “Start Threat Protection Analysis” to start
your analysis! 


                  </div>
                     <br/> <button onClick={getUserDateTime} className="uploadButton">Enter Date and Time</button>
                    </div>
                }
                <br/>
                      {uploadProgress === 100 && !uploading && threatProtectionChecked && startTime && <div>

                      <button onClick={commenceThreatProtection} className="uploadButton">Start Threat Protection Analysis</button>
                  
                    </div>
                  }



   */}

</div>
              </Col>

            </Row>




   </Container>  
    </div>
               
    </div>

</div>
<Footer/>
    </div>

  );
}


export default Connect;
