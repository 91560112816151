import React, { useState } from 'react';
import { Col, Row, Form,Button  } from 'react-bootstrap';
import { apiUrl } from './api';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";


function EmailIDField() {
  return (
    <Row className="mb-3">
      <Col md={12}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email ID</Form.Label>
          <Form.Control type="text" placeholder="Enter Email ID" />
        </Form.Group>
      </Col>
    </Row>
  );
}

function PasswordField() {
  return (
    <Row className="mb-3">
      <Col md={12}>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="text" placeholder="Enter password" />
        </Form.Group>
      </Col>
    </Row>
  );
}

function CameraPasswordField() {
  return (
    <Row className="mb-3">
      <Col md={12}>
        <Form.Group controlId="formCameraPassword">
          <Form.Label>Camera Password</Form.Label>
          <Form.Control type="text" placeholder="Enter camera password" />
        </Form.Group>
      </Col>
    </Row>
  );
}

function URLField() {
  return (
    <Row className="mb-3">
      <Col md={12}>
        <Form.Group controlId="formURL">
          <Form.Label>URL</Form.Label>
          <Form.Control type="text" placeholder="Enter Camera Portal URL" />
        </Form.Group>
      </Col>
    </Row>
  );
}

function CameraID() {
  return (
      <Row className="mb-3">
        <Col md={12}>
          <Form.Group controlId="formCameraID">
            <Form.Label>Camera ID</Form.Label>
            <Form.Control type="text" placeholder="Enter camera ID" />
          </Form.Group>
        </Col>
      </Row>
  );
}

export function CameraForm({ cameraID, emailID, cameraPassword, password, url,state }) {
    const { keycloak } = useKeycloak();

  const [submitted,setSubmitted] = useState(null)
    const [passwordValue, setPasswordValue] = useState('');
  const [cameraPasswordValue, setCameraPasswordValue] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [cameraIDValue, setCameraIDValue] = useState('');
  const [emailIDValue, setEmailIDValue] = useState('');



  const handleSubmit = async (event) => {
    event.preventDefault()  
    const response =await fetch(`${apiUrl}/updateCameraDetails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    password: passwordValue,
      cameraPassword: cameraPasswordValue,
      url: urlValue,
      cameraID: cameraIDValue,
      emailID: emailIDValue,
      user_email: keycloak.idTokenParsed.email,
      camera : state
  }),
  });
    console.log(response)

  if (!response.ok) {
    alert("Unabled to upload camera Details successfully. Please try again later.")
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  else{
    setSubmitted(true)
  }

  };



 return (
    <Col md={12}>
      <Form onSubmit={handleSubmit} style={{maxWidth:"100%",backgroundColor:"transparent",border:"none",boxShadow:"none"}}>
        {password && (
          <>
          <Form.Label>Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter password"
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              required
            />
          </>
        )}
        {cameraPassword && (
          <>
          <Form.Label>Camera Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter camera password"
              value={cameraPasswordValue}
              onChange={(e) => setCameraPasswordValue(e.target.value)}
              required
            />
          </>
        )}
        {url && (
          <>
        <Form.Label>Portal URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Camera Portal URL"
              value={urlValue}
              onChange={(e) => setUrlValue(e.target.value)}
              required
            />
          </>
        )}
        {cameraID && (
          <>
        <Form.Label>Camera ID</Form.Label>

            <Form.Control
              type="text"
              placeholder="Enter camera ID"
              value={cameraIDValue}
              onChange={(e) => setCameraIDValue(e.target.value)}
              required
            />
          </>
        )}
        {emailID && (
          <>
          <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email ID"
              value={emailIDValue}
              onChange={(e) => setEmailIDValue(e.target.value)}
              required
            />
          </>
        )}
      <div className="formButtonSubmit">
        <Button type="submit" className="formButton">Submit</Button>
        </div>
      </Form>
{submitted && <div>Your camera details have been submitted successfully. Please give it upto 48 hours to reflect the changes on SentriHub</div>}
    </Col>
  );
}

export function updateDashboardMargin() {

//   let footerElement = document.querySelector('.footer');
//   let headerElem = document.querySelector('.header-container');
//   let dashboardelem = document.querySelector('.main-content2');

//   if (footerElement && headerElem && dashboardelem) {
//     const headerBottom = headerElem.getBoundingClientRect().bottom;

//     dashboardelem.style.marginTop = `${headerBottom}px`;
 

// }

}