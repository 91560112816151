import { createStore, combineReducers } from 'redux';
import profileReducer from './profileReducer';

const rootReducer = combineReducers({
  profile: profileReducer,
  // Add other reducers if needed
});

const store = createStore(rootReducer);

export default store;