// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-container{

 z-index: 1000000000;
 background-color: white;
 top: 0;
height: 80px;

}

.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;
  display: flex; 
  justify-content: space-between; 
  align-items: center; 

box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

padding-right: 20px;

height: 80px;

}

.user-greet{
    display: flex;
    align-items: center;
    font-weight: bold;
}

@media (max-width: 576px) {
  .user-greet {
      }
}



.user-avatar{
    margin-left: 5px;
    border-radius: 20px;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex-inline;
    justify-content: space-around;
}

.header li {
    margin: 0 20px;
}

.header a {
    color: #fff;
    text-decoration: none;
}


.header div{
    padding: 15px;
}

.header button {
    margin-left: 10px;
    padding: 10px;
    padding-left: 20px;
    background-color: white;
    border: none;

}

.menu-toggle:hover {
  cursor: pointer;
}
.header img{
    height: 23px;
    margin-right:10px;
}

`, "",{"version":3,"sources":["webpack://./src/CSS/Header.css"],"names":[],"mappings":";AACA;;CAEC,mBAAmB;CACnB,uBAAuB;CACvB,MAAM;AACP,YAAY;;AAEZ;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,OAAO;IACP,WAAW;IACX,eAAe;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;;AAErB,wCAAwC;;AAExC,mBAAmB;;AAEnB,YAAY;;AAEZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;EACE;MACI;AACN;;;;AAIA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;;AAEhB;;AAEA;EACE,eAAe;AACjB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["\n.header-container{\n\n z-index: 1000000000;\n background-color: white;\n top: 0;\nheight: 80px;\n\n}\n\n.header {\n    position: sticky;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 100000;\n  display: flex; \n  justify-content: space-between; \n  align-items: center; \n\nbox-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n\npadding-right: 20px;\n\nheight: 80px;\n\n}\n\n.user-greet{\n    display: flex;\n    align-items: center;\n    font-weight: bold;\n}\n\n@media (max-width: 576px) {\n  .user-greet {\n      }\n}\n\n\n\n.user-avatar{\n    margin-left: 5px;\n    border-radius: 20px;\n}\n\n.header ul {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    display: flex-inline;\n    justify-content: space-around;\n}\n\n.header li {\n    margin: 0 20px;\n}\n\n.header a {\n    color: #fff;\n    text-decoration: none;\n}\n\n\n.header div{\n    padding: 15px;\n}\n\n.header button {\n    margin-left: 10px;\n    padding: 10px;\n    padding-left: 20px;\n    background-color: white;\n    border: none;\n\n}\n\n.menu-toggle:hover {\n  cursor: pointer;\n}\n.header img{\n    height: 23px;\n    margin-right:10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
