import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import arrows_icon from './assets/arrows_png.svg'
import loss_prevention from './assets/hand_icon.svg'
import bigboss_icon from './assets/bigboss_icon.svg'

import StaffSchedulingDashboardIcon from './assets/StaffSchedulingDashboardIcon.svg'

import shield from './assets/shield.svg'
import Footer from './Footer'; // I
import Sidebar from './Sidebar'; // 
import Header from './Header'; // 
import video_analysis from './assets/video_analysis.svg'

import video_upload from './assets/video_upload.svg'

import './CSS/Dashboard.css'; // 
import { Card,  } from 'react-bootstrap';

import {Col} from 'react-bootstrap';

import {isMobile} from 'react-device-detect';
import { apiUrl } from './api';


import { updateDashboardMargin } from './helperFunctions'; // Import the function
import axios from 'axios';


import { useKeycloak } from "@react-keycloak/web";




const adjustElementMinHeight = () => {
  console.log("C");
  const cards2 = document.querySelectorAll('.cards_cont');
  const cards = document.querySelectorAll('.main-content2');
  const header = document.querySelector('.header'); // replace with your header class or ID
  const footer = document.querySelector('.footer'); // replace with your footer class or ID

  if (cards.length > 0 && header && footer) {
    let headerHeight = header.offsetHeight;
    let footerHeight = footer.offsetHeight;  
    cards.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight}px)`;
    });
      cards2.forEach(card => {
      card.style.minHeight = `calc(100vh - ${footerHeight+headerHeight}px)`;
      card.style.marginBottom = `calc(${footerHeight}px)`;
    });
  }
};

function Dashboard() {
    useEffect(() => {
    // Run the function on component mount
    adjustElementMinHeight();

    // Add event listener for window resize
    window.addEventListener('resize', adjustElementMinHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustElementMinHeight);
    };
  }, []); // Empty dependency array means it will only run once on mount

  const {state} = useLocation();
  const {sidebar_state} = state

  const navigate = useNavigate(); 
 const { keycloak } = useKeycloak();
  const [isAIChecked, setAIChecked] = useState(false);
  const [lossPreventionChecked, setLossPreventionChecked] = useState(false);
  const [trafficAnalysisChecked, setTrafficAnalysisChecked] = useState(false);
  const [threatProtectionChecked, setThreatProtectionChecked] = useState(false);
  const [bigBossChecked, setBigBossChecked] = useState(false);
  const [workForce,setWorkForce] = useState(false)
  const [staffScheduling,setStaffScheduling] = useState(false)


  try{
    let body = {"email":keycloak.idTokenParsed.email}
  axios.post(`${apiUrl}/getDict`,body)
        .then((response) => {
          setAIChecked(response.data.safetyWithAI)
          setLossPreventionChecked(response.data.lossPrevention)
          setBigBossChecked(response.data.bigBoss)
          setTrafficAnalysisChecked(response.data.trafficAnalysis)
          setThreatProtectionChecked(response.data.threatProtection)
             setWorkForce(response.data.workForce)
          setStaffScheduling(response.data.staffScheduling)
          })
        .catch((error) => console.log(error));
  }catch{}
  
  let size = 0 

    size = 3
   
 
  const [sidebarVisible, setSidebarVisible] = useState(sidebar_state);

//   useEffect(() => {
//   window.addEventListener('margin_updater', updateDashboardMargin);
//   updateDashboardMargin(); // Initial update on mount 
//   return () => {
//     window.removeEventListener('margin_updater', updateDashboardMargin);
//   };
// }, [sidebarVisible,sidebar_state]);

  return (
    <div className="dashboard-container">

    <div className="dashboard">
        {sidebarVisible && <Sidebar active="dashboard" sidebar_state={sidebarVisible}/>}

      <div className="main-content">

    <div className="main-content2">
  <Header toggleMenu={() => setSidebarVisible(!sidebarVisible) && updateDashboardMargin} />
<div className="cards_cont">
    {
      // trafficAnalysisChecked && isAIChecked &&
      // <Card onClick={() => navigate('/traffic-analysis',{ state: { sidebar_state: sidebarVisible } })} className="custom_card" >

      //   <Card.Img src={arrows_icon} alt="Red image" className="img-fluid" />
      //   <Card.Body>
      //     <Card.Title>Traffic Analysis</Card.Title>
      //   </Card.Body>
      // </Card>
      }
      {
       // lossPreventionChecked && isAIChecked &&
      // <Card onClick={() => navigate('/loss-prevention',{ state: { sidebar_state: sidebarVisible } })} className="custom_card">
      //   <Card.Img src={loss_prevention} alt="Red image" className="img-fluid" />
      //   <Card.Body>
      //     <Card.Title>Loss Prevention</Card.Title>
      //   </Card.Body>
      // </Card>
      }
     
      {
      //   threatProtectionChecked && isAIChecked &&
      // <Card onClick={() => navigate('/threat-protection',{ state: { sidebar_state: sidebarVisible } })} className="custom_card">
      //   <Card.Img src={shield} alt="Red image" className="img-fluid" />
      //   <Card.Body>
      //     <Card.Title>Threat Protection</Card.Title>
      //   </Card.Body>
      // </Card>
   
    }

     {bigBossChecked &&

      <Card onClick={() => window.open('https://bigboss.sentrihub.com/login','bigbossWindow').focus()} className="custom_card">


        <Card.Img src={bigboss_icon} alt="Red image" className="img-fluid" />
        <Card.Body>
          <Card.Title>Collaboration</Card.Title>
        </Card.Body>
      </Card>
    }

      <Card onClick={() => navigate('/video_analysis',{ state: { sidebar_state: sidebarVisible } })} className="custom_card">
        <Card.Img src={video_analysis} alt="Red image" className="img-fluid" style={{padding:"35px"}} />
        <Card.Body>
          <Card.Title>Video Analysis</Card.Title>
        </Card.Body>
      </Card>

          <Card onClick={() => navigate('/connect',{ state: { sidebar_state: sidebarVisible } })} className="custom_card">
        <Card.Img src={video_upload} alt="Red image" className="img-fluid" />
        <Card.Body>
          <Card.Title>Upload Video</Card.Title>
        </Card.Body>
      </Card>



     {workForce && staffScheduling &&

      <Card onClick={() => window.open('https://staff.sentrihub.com','staffSchedulingWindow').focus()} className="custom_card">
        <Card.Img src={StaffSchedulingDashboardIcon} alt="Red image" className="img-fluid" />
        <Card.Body>
          <Card.Title>Staff Scheduling</Card.Title>
        </Card.Body>
      </Card>
    }

    </div>

</div>
               
    </div>

</div>
<Footer/>
    </div>

  );
}


export default Dashboard;
