// Importing React and necessary components and styles
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CSS/normal.css';
import './CSS/login.css';
import {  Form  } from 'react-bootstrap';
import { apiUrl } from './api';

import logo from './assets/sentrihub.png'
import Keycloak from 'keycloak-js';
import { useKeycloak } from "@react-keycloak/web";

// Main App component
function App() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

 const { keycloak, initialized } = useKeycloak();

   
   if (keycloak.authenticated) {
      const data = {
        email:keycloak.idTokenParsed.email
      }
      axios.post(`${apiUrl}/login`, data).then((getResponse) => {
        navigate('Dashboard',{ state: { sidebar_state: false } });
      })
    }
    const handleSubmit = (event) => {
    event.preventDefault();
  };


  return (


    <div class="container flex">
      <div class="login-page flex">
        <div className="inner-container">
          <img src={logo} className="sentri_img"/>
          <br/>
          <p className="welcome_text"> Welcome to SentriHub </p>

          <div class="link">
          <button class="login" onClick={()=>keycloak.login()}>
          Login
          </button>
          </div>
        </div>


    
      </div>
    </div>

  );
}

export default App;
