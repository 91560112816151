import './CSS/Header.css';
import menu_logo from './assets/menu.png';
import default_user from './assets/profile-user.png';
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function Header({ profileInfo, setProfileInfo, ...props }) {
  const navigate = useNavigate(); 

  const [sidebarVisible, setSidebarVisible] = useState(!isMobile);

  const [headerContainerWidth, setHeaderContainerWidth] = useState('100%');

  function toggleSidebar() {
    setSidebarVisible(!sidebarVisible);
    props.toggleMenu();
  }

 useEffect(() => {
    // Assuming you have a ref to your sidebar to measure its width
    const side_bar = document.querySelector('.sidebar');


    const updateHeaderWidth = () => {
      try{
      // const sidebarWidth = sidebarVisible ? `${side_bar.getBoundingClientRect().width}px` : '0px';
      // const calculatedWidth = `calc(100% - ${sidebarWidth})`;
      // setHeaderContainerWidth(calculatedWidth);
      }
      catch{
        
      }
    };

    // Update header width on mount and whenever sidebar visibility changes
    updateHeaderWidth();

    // Optional: If you want to update the width when the window resizes
    window.addEventListener('resize', updateHeaderWidth);
    return () => window.removeEventListener('resize', updateHeaderWidth);
  }, [sidebarVisible, props.sidebarRef]);

const headerContainerStyle = {
  width: headerContainerWidth,
};
function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

  return (
    <div className="header-container"  style={headerContainerStyle}>
      <div className="header">
   
         <button className="menu-toggle" id="menuToggle" onClick={toggleSidebar}>
          <img src={menu_logo} className="menu_logo" alt="Menu" />
        </button>
        
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  profileInfo: state.profile.profileInfo,
});

export default connect(mapStateToProps)(Header);