import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'react-bootstrap';

import './Table.css';

const TrafficAnalysisTable = ({ json, onCountClick }) => {

  const og_title = json['analysis']
  let title = ""
  if (og_title == "trafficAnalysis"){
    title = "Traffic Analysis"
  }
   if (og_title == "threatProtection"){
    title = "Threat Protection"
  }
   if (og_title == "lossPrevention"){
    title = "Loss Prevention"
  }
  const video_name = json['key']
  const jsonData = json["excelFile"]
  
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

function replaceUnderscores(inputString) {
    return inputString.replace(/_/g, ' ');
}
  console.log(jsonData)
  useEffect(() => {
  // Convert your JSON data to the format [{ Row: key, Count: value }, ...]
  const processedData = Object.keys(jsonData).map(key => ({
    Row: replaceUnderscores(key),
    Count: jsonData[key]
  }));

  setData(processedData);
}, [jsonData]);


  const pageCount = Math.ceil(data.length / rowsPerPage);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const getRowRangeText = () => {
    const startRow = (currentPage - 1) * rowsPerPage + 1;
    const endRow = Math.min(currentPage * rowsPerPage, data.length);
    return `Rows ${startRow} to ${endRow} of ${data.length}`;
  };

  return (
    <>
<div className="tableHeader">
<br/>
<div className="tableHeader2">
{/*<h3> {title +" "+ video_name}</h3>*/}
</div>
      <div className="data_table">

        <div className="inner_table">
          <Table bordered hover>
            <thead>
              <tr>
                {/* Table header content */}
              </tr>
            </thead>
            <tbody>
            {currentRows.map((row, index) => (
  <tr key={index} className="tablerows">
    <td className="tablecat">
      {row.Row === "Employees" || row.Row === "Visitors" ? (
        <span style={{ fontWeight: 'bold' }}>
          {row.Row === "Employees" ? "Employee Count" : "Visitor Count"}
        </span>
      ) : (
        row.Row
      )}
    </td>
    <td className="tablecount">
      {row.Row === "Employees" || row.Row === "Visitors" ? (
        <span style={{ fontWeight: 'bold' }}>{row.Count}</span>
      ) : (
<span 
  className="clickable" 
  style={{
    color:'rgb(200, 0, 4,1.0)',
    cursor:'pointer',
    position: 'relative',
  }}
  onClick={() => onCountClick(row.Count)}
>
  {row.Count}
  <span
    style={{
      position: 'absolute',
      top: '-20px', // Adjust as needed
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px',
      borderRadius: '5px',
      visibility: 'hidden',
    }}
    onMouseEnter={(event) => event.target.style.visibility = 'visible'}
    onMouseLeave={(event) => event.target.style.visibility = 'hidden'}
  >
    View in Video
  </span>
</span>      )}
    </td>
  </tr>
))}

            </tbody>
          </Table>
        </div>
        <div className="pagitems">
<span className="pagination-text">
  {getRowRangeText()}
</span>
          <button
            className={`arrow-button ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="arrow left"></i>
          </button>
          <button
            className={`arrow-button ${currentPage === pageCount ? 'disabled' : ''}`}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pageCount}
          >
            <i className="arrow right"></i>
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default TrafficAnalysisTable;
