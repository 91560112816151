import React, { useState, useEffect, useRef } from 'react';
import './CSS/main_app.css'; // This imports the CSS stylesheet for this component
import { useNavigate,useLocation } from 'react-router-dom';

import Footer from './Footer'; // I
import Sidebar from './Sidebar'; // 
import Header from './Header'; // 
import { Dropdown, DropdownButton, Row, Col, Button, Container,ButtonGroup } from 'react-bootstrap';
import { FaCalendar } from 'react-icons/fa';
import './CSS/Dashboard.css'; // 
import { FaCloudDownloadAlt } from 'react-icons/fa';

import TrafficAnalysisTable from './TrafficAnalysisTable';

import {isMobile} from 'react-device-detect';
import { updateDashboardMargin } from './helperFunctions'; // Import the function
import { useKeycloak } from "@react-keycloak/web";
import axios from 'axios';
import XLSX from 'xlsx';

import { apiUrl } from './api';

function Dashboard() {
    const {state} = useLocation();
  const {sidebar_state} = state
   const { keycloak } = useKeycloak();

  const [vid_arr, setVidArr] = useState([]);
 const fetchData = async () => {
    try {
      let body = { "email": keycloak.idTokenParsed.email };
      const response = await axios.post(`${apiUrl}/getList`, body);
      const data = response.data;
      let updatedVidArr = [];

      Object.keys(data).forEach(function (key) {
        let vid = data[key];
        let vid_name = key;
        vid_name = vid_name.replace((keycloak.idTokenParsed.email + "/"), "");
        Object.keys(vid).forEach(function (key2) {
          if (key2 === "lossPrevention") {
            updatedVidArr.push({
              [vid_name]: vid[key2]
            });
          }
        });
      });

      await setVidArr(updatedVidArr);
      try{
      await setSelectedKey("Sample Video");
    updatedVidArr.forEach((elem)=>{
      if (elem["Sample Video"]){
        setExcelFile(elem["Sample Video"]);
      }


     
    })
  }
  catch{}
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
useEffect(() => {
  fetchData();
}, []);

  const fileInputRef = useRef();
  const [excelFile, setExcelFile] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const handleDropdownSelect = (eventKey) => {
    setSelectedKey(eventKey);
    vid_arr.forEach((elem)=>{
      if (elem[eventKey]){
        setExcelFile(elem[eventKey]);
      }
     
    })
   
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    setExcelFile(file);
  };

  function handleConnectClick(){
    navigate('/connect',{ state: { sidebar_state: sidebarVisible } })
  }

  const navigate = useNavigate(); 
  const [sidebarVisible, setSidebarVisible] = useState(sidebar_state);


  useEffect(() => {
 
  window.addEventListener('margin_updater', updateDashboardMargin);
  updateDashboardMargin(); // Initial update on mount
  return () => {
    window.removeEventListener('margin_updater', updateDashboardMargin);
  };

}, [sidebarVisible,excelFile]); 


  return (
    <div className="dashboard-container">


    <div className="dashboard">
        {sidebarVisible && <Sidebar />}

      <div className="main-content">
  <Header toggleMenu={() => setSidebarVisible(!sidebarVisible) && updateDashboardMargin} />
<div className="main-content2">
<div className = "tpage_title">
    <h1>Loss Prevention</h1>
    </div>
    </div>
  

<div className ="table_button_group">
    <ButtonGroup size="lg" className="mb-2">

        <DropdownButton
          title={
            <>
              <FaCalendar className="calendar-icon" /> Video
            </>
          }
          variant="outline-secondary"
          id="date-dropdown"
className="dropdown_new" 
          onSelect={handleDropdownSelect}
        >
              {vid_arr.length > 0 ? (
        vid_arr.map((item, index) => (
        <Dropdown.Item key={index} eventKey={Object.keys(item)[0]}>
  {Object.keys(item)[0]}
</Dropdown.Item>  
        ))
      ) : (
        <Dropdown.Item disabled>No options available</Dropdown.Item>
      )}
        </DropdownButton>
   <Button onClick={handleConnectClick} className="uploadButton_new">
      Upload Video
    </Button>
         
      </ButtonGroup>
        <Button className="download_data_button" disabled>
      <FaCloudDownloadAlt className="" />
    </Button>
  </div>
            <div className="handle_table">

                   {excelFile && <TrafficAnalysisTable jsonData={excelFile} />}
          


                     </div>

          
    </div>


</div>
<Footer/>
    </div>

  );
}

export default Dashboard;